import FormInput from "./FormInput";

const InputDateTime = (props) => {
    const {name, title, handleInputChange, defaultValue, className} = props
    return (
        <>
           <FormInput {...props} handleInputChange={handleInputChange} name={name} className={className } defaultValue={defaultValue} title={title} type={"datetime-local"}  />
        </>

    );
}

export default InputDateTime;
