import React, { cloneElement, createContext, useEffect, useMemo, useRef, useState, } from "react";
import { cn } from "../../helpers/class_name.helpers";
import { CgChevronDown } from "react-icons/cg";
import SelectedOption from "./SelectedOption";

export const SelectContext = createContext();

const Select = ({ children, defaultValue = "", onChange , searchable = false , options = [] }) => {
  const [selectedValue, setSelectedValue] = useState();
  const [selectedContent, setSelectedContent] = useState(null);
  const [visible, setVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const selectRef = useRef(null);

  const memoizedValue = useMemo(() => {
      return {
          value : selectedValue,
      }
  } , [selectedValue])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const onSelect = (value, content) => {
    setSelectedValue(value);
    setSelectedContent(
      <SelectedOption clearSelection={clearSelection} content={content} />
    );
    setVisible(false);

    if (onChange) {
      onChange(value);
    }
  };

  const clearSelection = () => {
    setSelectedValue("");
    setSelectedContent(null);
  };

  const filteredChildren = React.Children.toArray(children).filter(
    (child) =>{

    if(!React.isValidElement(child)) return false

    if(child.props.text)
      return child.props.text.toLowerCase().includes(searchTerm.toLowerCase())

    if(typeof child.props === "string")
      return child.props.toLowerCase().includes(searchTerm.toLowerCase())
    
     return child.props.value.toLowerCase().includes(searchTerm.toLowerCase())
    }
  );




    const memoizedChildren = useMemo(() => React.Children.toArray(children), [children]);

    // console.log({
    //   memoizedChildren
    // })

    useEffect(() => {
      if (defaultValue) {
        const defaultOption = memoizedChildren.find(
          (child) =>
            React.isValidElement(child) && child.props.value === defaultValue
        );

        if (defaultOption && selectedValue !== defaultValue) {
          onSelect(defaultValue, defaultOption.props.children);
        }
      }
    }, [defaultValue, selectedValue, memoizedChildren]);

  


  return (
    <SelectContext.Provider value={{ selectedValue, onSelect }}>
      <div
        ref={selectRef}
        className="border-border border-[2px] rounded-md min-h-[45px] flex items-center px-3 cursor-pointer relative">
        <button className="w-full text-end" onClick={() => setVisible(!visible)}>
          {selectedContent || "اختر من القائمة"}
          <div
            className={cn(
              "absolute left-2 top-1/2 -translate-y-1/2 transition-transform",
              visible && "rotate-180"
            )}>
            <CgChevronDown />
          </div>
        </button>

        {visible && (
          <div className="bg-white p-2 rounded-md shadow-sm absolute top-[105%] left-0 right-0 !z-[9999999999999999999999]">
            {
              searchable && <input type="text" placeholder="ابحث عن العنصر" value={searchTerm}
              className="w-full p-2 mb-2 rounded-md border focus:outline-none"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            }

            <div className="max-h-[300px] overflow-auto">
              {filteredChildren.length ? (
                filteredChildren.map((child) =>
                  cloneElement(child, { key: child.props.value })
                )
              ) : (
                <div className="p-2 text-gray-500">لا يوجد نتائج</div>
              )}
            </div>
          </div>
        )}
      </div>
    </SelectContext.Provider>
  );
};


export default Select