import { BiTrash } from "react-icons/bi"; 
import { CgChevronDown } from "react-icons/cg"; 
import { CgOptions } from "react-icons/cg"; 
import { BiCheck } from "react-icons/bi"; 
import React, { cloneElement, useEffect, useRef } from 'react';
import Pagination from "../../components/pagination/Pagination";
import PageMeta from "../../components/PageMeta";
import { apis } from "../../config/apis";
import usePaginate from "../../hooks/usePaginate";
import SearchInput from '../../components/search/SearchInput';
import Invoices from '../_invoices/Invoices';

import { createContext, useContext, useState } from 'react';
import { cn } from '../../helpers/class_name.helpers';
import { Option, Select } from "../../components/_select";




const TestingHelperIndex = (props) => {


  
  // const Paginate = usePaginate(apis.notifications.paginate())


  return (
    <>
      <PageMeta title="testing " />
      {/* <div className="flex items-center justify-center my-10 flex-col">
            <Pagination />
            <SearchInput /> */}
            {/* <Paginate /> */}
      {/* </div> */}


        {/* <Invoices /> */}


  <div className="p-10">
      <h1 className="mb-4 text-xl font-bold">Select Component with Search</h1>
      <div className="row">
        <div className="col-6">
        <Select>
        <Option value="option1">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/158827/field-corn-air-frisch-158827.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 1
          </div>
        </Option>
        <Option value="option2">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/844297/pexels-photo-844297.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 2
          </div>
        </Option>
        <Option value="option3">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/691668/pexels-photo-691668.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 3
          </div>
        </Option>
        <Option value="option4">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/1231239/pexels-photo-1231239.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 4
          </div>
        </Option>

        <Option value="option5">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/357155/pexels-photo-357155.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 5
          </div>
        </Option>

        <Option value="option6">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/34950/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 6
          </div>
        </Option>

        <Option value="option7">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/325520/pexels-photo-325520.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 7
          </div>
        </Option>

        <Option value="option8">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/733745/pexels-photo-733745.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 8
          </div>
        </Option>

        <Option value="option9">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/15286/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 9
          </div>
        </Option>

        <Option value="option10">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/1173777/pexels-photo-1173777.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 10
          </div>
        </Option>

        <Option value="option11">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/258385/pexels-photo-258385.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 11
          </div>
        </Option>

        <Option value="option12">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/1173775/pexels-photo-1173775.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 12
          </div>
        </Option>

        <Option value="option13">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/161235/pexels-photo-161235.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 13
          </div>
        </Option>

        <Option value="option14">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/1442442/pexels-photo-1442442.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 14
          </div>
        </Option>

        <Option value="option15">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/1640777/pexels-photo-1640777.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 15
          </div>
        </Option>

        <Option value="option16">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/1174029/pexels-photo-1174029.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 16
          </div>
        </Option>

        <Option value="option17">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/1255075/pexels-photo-1255075.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 17
          </div>
        </Option>

        <Option value="option18">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/210547/pexels-photo-210547.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 18
          </div>
        </Option>

        <Option value="option19">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/1864601/pexels-photo-1864601.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 19
          </div>
        </Option>

        <Option value="option20">
          <div className="flex items-center gap-2">
            <img src="https://images.pexels.com/photos/1909281/pexels-photo-1909281.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
            Option 20
          </div>
        </Option>

      </Select>
        </div>
        <div className="col-6">
                <Select defaultValue="option1" onChange={(e) => console.log(e)}>
                  <Option value="option1">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/158827/field-corn-air-frisch-158827.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 1
                    </div>
                  </Option>
                  <Option value="option2">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/844297/pexels-photo-844297.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 2
                    </div>
                  </Option>
                  <Option value="option3">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/691668/pexels-photo-691668.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 3
                    </div>
                  </Option>
                  <Option value="option4">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/1231239/pexels-photo-1231239.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 4
                    </div>
                  </Option>

                  <Option value="option5">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/357155/pexels-photo-357155.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 5
                    </div>
                  </Option>

                  <Option value="option6">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/34950/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 6
                    </div>
                  </Option>

                  <Option value="option7">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/325520/pexels-photo-325520.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 7
                    </div>
                  </Option>

                  <Option value="option8">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/733745/pexels-photo-733745.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 8
                    </div>
                  </Option>

                  <Option value="option9">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/15286/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 9
                    </div>
                  </Option>

                  <Option value="option10">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/1173777/pexels-photo-1173777.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 10
                    </div>
                  </Option>

                  <Option value="option11">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/258385/pexels-photo-258385.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 11
                    </div>
                  </Option>

                  <Option value="option12">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/1173775/pexels-photo-1173775.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 12
                    </div>
                  </Option>

                  <Option value="option13">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/161235/pexels-photo-161235.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 13
                    </div>
                  </Option>

                  <Option value="option14">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/1442442/pexels-photo-1442442.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 14
                    </div>
                  </Option>

                  <Option value="option15">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/1640777/pexels-photo-1640777.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 15
                    </div>
                  </Option>

                  <Option value="option16">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/1174029/pexels-photo-1174029.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 16
                    </div>
                  </Option>

                  <Option value="option17">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/1255075/pexels-photo-1255075.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 17
                    </div>
                  </Option>

                  <Option value="option18">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/210547/pexels-photo-210547.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 18
                    </div>
                  </Option>

                  <Option value="option19">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/1864601/pexels-photo-1864601.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 19
                    </div>
                  </Option>

                  <Option value="option20">
                    <div className="flex items-center gap-2">
                      <img src="https://images.pexels.com/photos/1909281/pexels-photo-1909281.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[30px] h-[30px] rounded-md object-cover" alt="" />
                      Option 20
                    </div>
                  </Option>

              </Select>
        </div>
          </div>
        </div>
    </>
  );
};


export default TestingHelperIndex;



// const SelectContext = createContext();

// export const Select = ({ children, defaultValue = "", onChange }) => {
//   const [selectedValue, setSelectedValue] = useState(defaultValue);
//   const [selectedContent, setSelectedContent] = useState(null);
//   const [visible, setVisible] = useState(false);
//   const [searchTerm, setSearchTerm] = useState("");
//   const selectRef = useRef(null);

  
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (selectRef.current && !selectRef.current.contains(event.target)) {
//         setVisible(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => document.removeEventListener("mousedown", handleClickOutside);
//   }, []);

//   const onSelect = (value, content) => {
//     setSelectedValue(value);
//     setSelectedContent(
//       <div
//         className="cursor-pointer w-fit relative group"
//         onClick={(e) => {
//           e.stopPropagation();
//           clearSelection();
//         }}
//       >
//         <div className="z-[5]">{content}</div>
//         <div
//           className={cn(
//             "absolute w-full h-full flex items-center justify-center z-[10] top-0 left-0 bg-red-500/50 rounded-md opacity-0",
//             "group-hover:!opacity-[1]",
//             "transition-all duration-300 ease-in-out"
//           )}
//         >
//           <BiTrash className="fill-white" />
//         </div>
//       </div>
//     );
//     setVisible(false);

//     if (onChange) {
//       onChange(value);
//     }
//   };

//   const clearSelection = () => {
//     setSelectedValue("");
//     setSelectedContent(null);
//   };

//   const filteredChildren = React.Children.toArray(children).filter(
//     (child) =>
//       React.isValidElement(child) &&
//       child.props.value.toLowerCase().includes(searchTerm.toLowerCase())
//   );




//   useEffect(() => {
//     if (defaultValue) {
//       const defaultOption = React.Children.toArray(children).find(
//         (child) =>
//           React.isValidElement(child) &&
//           child.props.value === defaultValue
//       );
//       if (defaultOption) {
//         onSelect(defaultValue, defaultOption.props.children);
//       }
//     }
//   }, [defaultValue]);

//   return (
//     <SelectContext.Provider value={{ selectedValue, onSelect }}>
//       <div
//         ref={selectRef}
//         className="border-border border-[2px] rounded-md min-h-[45px] flex items-center px-3 cursor-pointer relative"
//       >
//         <button className="w-full text-end" onClick={() => setVisible(!visible)}>
//           {selectedContent || "اختر من القائمة"}
//           <div
//             className={cn(
//               "absolute left-2 top-1/2 -translate-y-1/2 transition-transform",
//               visible && "rotate-180"
//             )}
//           >
//             <CgChevronDown />
//           </div>
//         </button>

//         {visible && (
//           <div className="bg-white p-2 rounded-md shadow-sm absolute top-[105%] left-0 right-0 z-[10]">
//             <input
//               type="text"
//               className="w-full p-2 mb-2 rounded-md border focus:outline-none"
//               placeholder="ابحث عن العنصر"
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//             />
//             <div className="max-h-[300px] overflow-auto">
//               {filteredChildren.length ? (
//                 filteredChildren.map((child) =>
//                   cloneElement(child, { key: child.props.value })
//                 )
//               ) : (
//                 <div className="p-2 text-gray-500">No options found</div>
//               )}
//             </div>
//           </div>
//         )}
//       </div>
//     </SelectContext.Provider>
//   );
// };

// export const Option = ({ value, children }) => {
//   const context = useContext(SelectContext);

//   if (!context) 
//     throw new Error("Option must be used within a Select component");
  
//   const { onSelect, selectedValue } = context;

//   return (
//     <div
//       onClick={() => onSelect(value, children)}
//       className={cn(
//         "cursor-pointer hover:bg-slate-100 p-2 px-4 my-1 rounded-md relative",
//         selectedValue === value && "bg-slate-100"
//       )}
//     >
//       {children}
//       {selectedValue === value && (
//         <BiCheck className="absolute right-1 top-1/2 transform -translate-y-1/2" />
//       )}
//     </div>
//   );
// };
