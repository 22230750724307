import React from "react";
import PageMeta from "../../components/PageMeta";

import { accio } from "../../helpers/axios/axios.get";
import { operators_api } from "../../config/apis";
import { Selectly } from "../../libs/select";
import InputDrugList from "../../shared/components/drug-origin/InputDrugList";

const TestingHelperIndex = (props) => {
  return (
    <>
      <PageMeta title="testing " />
      <InputDrugList
        handleInputChange={(e) => {
          console.log(e);
        }}
      />
    </>
  );
};

export default TestingHelperIndex;
