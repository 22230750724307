import { _date, createFormHandler, EditForm, InputDate, InputNumber, InputSelect, InputText, InputTextArea, PageMeta, setInputsToDefault, Signboard, useFetch } from "../../../config/imports";
import { apis } from "../../../config/apis";
import { useEffect, useMemo, useState } from "react";

import MeasuringUnitInput from "../shared/MeasuringUnitInput";
import UnitPriceInput from "../shared/UnitPriceInput";
import AmountInput from "../shared/AmountInput";
import TotalPriceInput from "../shared/TotalPriceInput";
import OperatorsInput from "../shared/OperatorsInput";
import InvoiceId from "../shared/InvoiceId";
import useInvoicesApi from "./useInvoicesApi";
import InputTax from "../shared/InputTax";
import useInvoiceItemsStore from "../stores/invoice-items.store";
import InputEnableTax from "../components/InputEnableTax";
import InputDishesGroup from "../shared/InputDishesGroup";

export default function BuyDishesInvoice(props){

    const {
        warehouse,
        setMainData, toggle ,
        setRefresh, stockName , stockId
    } = props

    const resetItems = useInvoiceItemsStore(state => state.resetItems)

    useEffect(() => {
        resetItems()
    }, [])

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    // const project = '64fd0c1953b4852b68cd00c6'
    // const department = '64fd0c1953b4852b68cd00c8'

    const [data , setData] = useState({
        // home_id,
        invNumber : '',
        operator: '',
        date : new Date().toISOString(),
        sub_category : '' ,
        measuring_unit : '',
        unit_price : 0,
        quantity : 0,
        total_price : 0,
        more_info : '',
        home : [],
        orders : [],
        stock_quantity : "",
        invoice_type : "stock",
    })




    const {  data : formData , error : formError  , Alert , setters , OrdersElement } = useInvoicesApi( 
        { type : "buy" , invoice : "dish" , place : "stock" , place_id :  warehouse } ,
        { data  , setData  })

    const signboard = {
        loading :   loading ,
        error :   error ,
        success,
    }




        
    useEffect(()=>{

        if(formData){
            setData((prev) => ({
                ...prev,
                stock : stockId ??  formData?.storage_info?.location_id
            }))
        }

    } , [formData])




    const measuringUnits = useMemo(() => {
        return [
            { text : "قطعه" , value : "piece" },
        ]
    } , [])


    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));

    };


    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const create_props = {
            url: apis.invoices.buy.dishes.create(),
            data : {
                    ...data ,
                    stock : stockId ?? formData?.storage_info?.location_id,
                    stock_quantity : data?.quantity,
                    items : useInvoiceItemsStore.getState().getItems(),
                    quantity : useInvoiceItemsStore.getState().getTotalQuantity()
            }  ,
            setLoading,
            setError,
            setSuccess,
            setMainData ,
            setRefresh,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };
        // console.log(create_props.data)

        await createFormHandler(create_props);
    };

    return(
        <div className={''}>
            <PageMeta title={'فاتورة شراء اطباق'} originTitle={'ادارة الفواتير'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>فاتورة شراء اطباق</h3>
            </header>

            <Signboard {...signboard}/>
            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>

                <InvoiceId className={"col-3"} setData={setData} type={"dishBuyingInv"}   value={formData?.inv_number} />

                <InputText name="invoice_number" className="col-3"   title=" رقم الفاتورة" defaultValue={data.invoice_number} handleInputChange={handleInputChange} />

                <OperatorsInput data={data} setData={setData} />

                <InputDate className={'col-3'}  name="date" label="تاريخ الفاتورة" defaultValue={_date(data.date).forInputDate()} handleInputChange={handleInputChange} />

                {/* <InputSelect  className={'col-3'}  defaultValue={data.sub_category} name={'sub_category'} options={ dishesOptions } title={'نوع الطبق'} /> */}

                {/* <MeasuringUnitInput data={data} setData={setData} /> */}
                {/* <InputSelect name={'unit'} options={measuringUnits} title={'وحدة القياس'} handleInputChange={handleInputChange} className={'col-3'}   /> */}


                {/* <UnitPriceInput data={data} setData={setData} /> */}

                {/* <AmountInput data={data} setData={setData} /> */}

                <InputDishesGroup data={data} setData={setData} />

                <OrdersElement defaultValue={data.orders}  />

                <InputText name={"stock"}  title={"اسم المستودع"} defaultValue={stockName ?? "المستودع الخاص بالمشروع"}  disabled={true} className={"col-4"} />
              
                {/* 
                    <>
                        <div className={"stick-error-to-input bb-2  px-0 col-4  " + (error === "الكميات غير متطابقه" ? 'input-has-error ' : '')}>
                                <InputNumber name="stock_quantity"  handleInputChange={handleInputChange}   title={"الكميه"} defaultValue={data.stock_quantity}   className={"w-100"}  />
                                <small className="text-danger px-0 py-0 error-output">{
                                data.quantity && data.stock_quantity && ( ( data.stock_quantity ?? 0) !==  (data.quantity ?? 0)) ?  "الكميات غير متطابقه" : ""
                                    }
                                </small>
                        </div>
                    </>

                <TotalPriceInput className="col-4" data={data} setData={setData} /> */}

                                
                {/* <TotalPriceInput className="col-3" data={data} setData={setData} />

                <InputTax className="col-5"  data={data} setData={setData} /> */}

                                
                <TotalPriceInput className="col-2" data={data} setData={setData} />

                <div className="col-2">
                    <InputEnableTax data={data} setData={setData}   />
                </div>


                <InputTax className="col-4"  data={data} setData={setData} />

                <InputTextArea name="more_info" title="ملاحظات" value={data.more_info} handleInputChange={handleInputChange} />

            </EditForm>

        </div>
    )
}
