import {InputSelect} from "../../../config/imports";
import useCollectionCenter from "../../../hooks/origins/useCollectionCenter";

export default function BuyInvoiceComponent(props){
    const { handlers , operators, projects , departments , baseInv }= props



  const { Input, getName, options } = useCollectionCenter();


    return(
        <div className="row  index-inv-container">
            <InputSelect
                title={"العميل / المورد"}
                className={"col-4 mb-4 col-md-4 col-lg-4 col-sm-4"}
                options={operators}
                handleInputChange={handlers.shared.change}
                name="buy_inv_operator_id"
            />
            <InputSelect
                title={"نوع الفاتورة"}
                className={"col-4 mb-4 col-md-4 col-lg-4 col-sm-4"}
                options={[
                    { _id: "فاتورة بيض", name: "فاتورة بيض" },
                    //to be added
                ]}
                handleInputChange={handlers.shared.change}
                name="buy_inv_type"
            />
            {/* <InputSelect
                title={"اسم المشروع"}
                className={"col-4 mb-4 col-md-4 col-lg-4 col-sm-4"}
                options={projects}
                handleInputChange={handlers.sell.project}
                name="buy_inv_project_id"
            /> */}

            <Input
                title={" نقطة التجميع"}
                className={"col-4 mb-4 col-md-4 col-lg-4 col-sm-4"}
                handleInputChange={handlers.sell.collectionCenter}
                name="buy_inv_project_id"

            />
            {/* <InputSelect
                title={"القسم"}
                className={"col-12 mb-4 col-md-4 col-lg-4 col-sm-12"}
                options={departments}
                handleInputChange={handlers.buy.department}
                name="buy_inv_department_id"
            />
            <InputSelect
                title={"البيت"}
                className={"col-12 mb-4 col-md-4 col-lg-4 col-sm-12"}
                options={baseInv.buy_inv_homes}
                handleInputChange={handlers.buy.home}
                name="buy_inv_home_id"
            /> */}

        </div>
    )
}
