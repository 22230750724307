import useFetch from "../../../hooks/useFetch";
import { operatorAPI, originAPI } from "../origin.helpers";
import { Icons, QuickView, Signboard, TableHeader, useRef } from "../../../config/imports";
import PageMeta from "../../../components/PageMeta";
import React from "react";
import OriginDrugCrud from "./drug_crud";
import Gadwal from "../../../shared/components/gadwal/Gadwal";
import QuickViewWithOverFlow from "../../../components/QuickViewWithOverFlow";

const Drug = () => {
    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();



    const { data: drugs, error, isLoading, setData : setDrugs  , setRefresh} = useFetch(originAPI.drug);

    const { data: operators  , isLoading :  operatorLoading , error : operatorError} = useFetch(operatorAPI.url);

    const signboard =  {
        error : error || operatorError,
        loading : isLoading || operatorLoading
    }



    const table = [
        {  size : 1 , name : "#" , header : "#" , custom : (d , idx) => idx},
        {  size : 5 , name : "name" , header : "الاسم"  },
        {  size : 3 , name  : "ar_unit"  , header : "وحدة القياس" },
        {  size : 2 , name  : "classification"  , header : "النوع" },
        {  size : 6 , name : "operator" , header : "المورد"  , custom : d => d.operator?.name ?? 'بدون'  },
        {  size : 5 , name : "info" , header : "التفاصيل"  , custom : d => d.info || "-" },
        {  size : 2 , name : "" , header : "" , custom : d => {
            return <div className="flex items-center gap-2">
                       <button  onClick={()=> updateRef.current.toggle(d)}><Icons.Edit /></button>
                       <button  onClick={()=> deleteRef.current.toggle(d)}><Icons.Delete /></button>
            </div>
        }}
    ]


    return (
    <>
      <PageMeta title={"الادوية"} />
        <QuickViewWithOverFlow ref={createRef}><OriginDrugCrud.Create  operators={operators} setRefresh={setRefresh}  setMainData={setDrugs}/></QuickViewWithOverFlow>
        <QuickViewWithOverFlow ref={updateRef}><OriginDrugCrud.Update  operators={operators} setRefresh={setRefresh} setMainData={setDrugs}/></QuickViewWithOverFlow>
        <QuickView ref={deleteRef}><OriginDrugCrud.Delete  operators={operators} setRefresh={setRefresh} setMainData={setDrugs}/></QuickView>
        <Signboard {...signboard} />
        <TableHeader  buttons={ [
            {
                title: " اضافة نوع دواء جديد ",
                onclick: () => createRef.current.toggle()
            }
        ]}/>
        <div className="" style={{marginTop: '150px'}}></div>

        {/* <Table  data={drugs}  table={table} /> */}

        <Gadwal table={table} data={drugs} />

    </>
  );
};

export default Drug;
