import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { reports_api } from "../../../../../config/apis";
import { _date, InputDate, InputNumber, InputSelect, InputTextArea } from "../../../../../config/imports";
import CreateForm from "../../../../../components/crud/CreateForm";
import useStockOthers from "../../../../stocks/hooks/useStockOthers";
import useDebounce from "../../../../../hooks/useDebounce";
import { useOtherReportQuantityPrice } from "../../hooks/useReportQuantityPrice";
import InputDateTime from "../../../../../components/FormControls/InputDateTime";
import { cn } from "../../../../../helpers/class_name.helpers";

export default function Create(props){

    const {id} = useParams();
    const {setRefresh, toggle} = props;

    const [state , setState] = useState({
        date : new Date().toISOString(),
        start_date : new Date().toISOString(),
        end_date : new Date().toISOString(),
        notes : '',
    })



    const createProps = {
        setState,
        state,
        api : reports_api.home.lights.create(),
        toggle,
        header : 'اضافة تقرير اضاءة  يومي',
        setRefresh,
        customState : (data) => {
            return {
                ...data,
                home : id,
                date : _date(data.date).toZeroUTC()
            }
        }
    }



    const difference = getDifferenceInHours(state.start_date , state.end_date)

    return(
       <>
           <CreateForm {...createProps}>
               <InputDate    className="col-6"  name='date'    title='تاريخ التقرير'  defaultValue={_date(state.date).forInputDate()} />
                <>
                 <div className="col-6">
                    <span className="relative bottom-2">مدة الاضاءة بالساعة</span>
                    <div className={cn(
                        "w-full bg-slate-100 h-[40px] rounded-md flex items-center justify-start  truncate  px-2",
                        difference < 0 ? "bg-red-300" : "bg-slate-100"
                    )}>
                    {  difference }
                    </div>
                 </div>
                </>
                <InputDateTime name='start_date' className='col-6' defaultValue={_date(state.start_date).forInputDateTime()}   title='تاريخ بدء الاضاءة' />
                <InputDateTime name='end_date' className='col-6'  defaultValue={_date(state.end_date).forInputDateTime()}  title='تاريخ نهاية الاضاءة'  />
            
               <InputTextArea name='notes'   placeholder=' ملاحظات على تقارير الاضاءة... '  title='ملاحظات' />
           </CreateForm>
       </>
    )
}


function getDifferenceInHours(startDate , endDate) {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTime = (date2.getTime() - date1.getTime());
    const diffHours = (diffTime / (1000 * 60 * 60)).toFixed(2);
    return diffHours;
}
