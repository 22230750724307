import { _date, createFormHandler, EditForm, InputDate, InputText, InputTextArea, PageMeta, setInputsToDefault, Signboard } from "../../../config/imports";
import { apis } from "../../../config/apis";
import { useEffect, useMemo, useState } from "react";
import TotalPriceInput from "../shared/TotalPriceInput";
import OperatorsInput from "../shared/OperatorsInput";
import InvoiceId from "../shared/InvoiceId";
import useInvoicesApi from "./useInvoicesApi";
import InputTax from "../shared/InputTax";
import InputDrugsGroup from "../shared/InputDrugsGroup";
import InputEnableTax from "../components/InputEnableTax";
import useInvoiceItemsStore from "../stores/invoice-items.store";
export default function BuyDrugsInvoice(props){
    const { project , setMainData, toggle , setRefresh, stockId , stockName } = props
    const resetItems = useInvoiceItemsStore(state => state.resetItems)

    useEffect(() => {
        resetItems()
    }, [])


    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [data , setData] = useState({
        invNumber : '',
        operator: '',
        date : new Date().toISOString(),
        sub_category : '' ,
        measuring_unit : '',
        unit_price : 0,
        quantity : 0,
        total_price : 0,
        more_info : '',
        project,
        stock_quantity : "",
        invoice_type : "stock",
        stock : "" ,
        orders : []
    })








    const {  data : formData , error : formError , Alert , setters , signboard : _signboard , OrdersElement  } = useInvoicesApi( { 
        type : "buy" , invoice : "drug" , 
        place : "stock" , place_id : stockId 
    } , {data  , setData }
)
    const ordersOptions = useMemo(() => {
        return formData?.all_orders?.map( o => ({ text : o.order_number , value : o._id  }) )
    } , [formData?.all_orders])


    const signboard = {
        loading :   loading   || _signboard.loading   ,
        error :   error  || _signboard.error,
        success,
    }
    useEffect(()=>{
        if(formData){
            setData((prev) => ({
                ...prev,
                stock : stockId ??  formData?.storage_info?.location_id
            }))
        }
    } , [formData])
    const measuringUnits = useMemo(() => {
        return [
            { text : "قطعه" , value : "piece" },
        ]
    } , [])

    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };


    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        const create_props = {
            url: apis.invoices.buy.drug.create(),
            data  : {
                ...data ,
                stock : stockId ?? formData?.storage_info?.location_id,
                stock_quantity : data?.quantity,
                items : useInvoiceItemsStore.getState().getItems(),
                quantity : useInvoiceItemsStore.getState().getTotalQuantity()
            } ,
            setLoading,
            setError,
            setSuccess,
            setMainData ,
            setRefresh,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };
        await createFormHandler(create_props);
    };


    return(
        <div className={''}>
            <PageMeta title={'فاتورة شراء ادويه'} originTitle={'ادارة الفواتير'}/>
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>فاتورة شراء ادويه</h3>
            </header>

            <Signboard {...signboard}/>
            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>
                <InvoiceId className={"span-5"} setData={setData} type={"drugBuyingInv"}   value={formData?.inv_number} />
                <InputText name="invoice_number" className="span-5"   title=" رقم الفاتورة" defaultValue={data.invoice_number} handleInputChange={handleInputChange} />
                <OperatorsInput data={data} className="col-4" setData={setData} />
                <InputDate className={'col-3'}  name="date" label="تاريخ الفاتورة" defaultValue={_date(data.date).forInputDate()} handleInputChange={handleInputChange} />

                {/* <InputSelect name={'unit'} options={measuringUnits} title={'وحدة القياس'} handleInputChange={handleInputChange} className={'col-3'}   />
                <UnitPriceInput data={data} setData={setData} />
                <AmountInput data={data} setData={setData} /> */}
                <InputDrugsGroup  data={data} setData={setData} />
                <OrdersElement defaultValue={data.orders}  />
                <InputText name={"stock"}  title={"اسم المستودع"} defaultValue={stockName ?? "المستودع الخاص بالمشروع"}  disabled={true} className={"col-4"} />
                {/* <>
                    <div className={"stick-error-to-input bb-2  px-0 col-3  " + (error === "الكميات غير متطابقه" ? 'input-has-error ' : '')}>
                            <InputNumber name="stock_quantity"  handleInputChange={handleInputChange}   title={"الكميه"} defaultValue={data.stock_quantity}   className={"w-100"}  />
                            <small className="text-danger px-0 py-0 error-output">
                                {
                                 data.quantity && data.stock_quantity && ( ( data.stock_quantity ?? 0) !==  (data.quantity ?? 0)) ?  "الكميات غير متطابقه" : ""
                                }
                            </small>
                        </div>
                </>
                  */}
                 <TotalPriceInput className="col-2" data={data} setData={setData} />
                <div className="col-2">
                    <InputEnableTax data={data} setData={setData}    />
                </div>
                <InputTax className="col-4"  data={data} setData={setData} />
                <InputTextArea name="more_info" title="ملاحظات" value={data.more_info} handleInputChange={handleInputChange} />
            </EditForm>
        </div>
    )
}
