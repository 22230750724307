import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
    _date,
    Grid,
    Icons,
    PageMeta,
    QuickView,
    Signboard,
    TableHeader,
    useFetch
} from "../../../../config/imports";
import ReportsCrud from "../reports_crud";
import { reports_api } from "../../../../config/apis";
import Helpers from "../../../../helpers/helpers";
import { Table } from "../../../../layout/table";

export default function EggsDailyReportForProductionHome(props) {
    const deleteRef = useRef(),
        createRef = useRef(),
        updateRef = useRef(),
        viewRef = useRef();
    const { id } = useParams();
    const api = reports_api.home.eggs.getHomeEggsReports(id)
    const { data, setData, alert, paginate, setters, setRefresh } = useFetch(api)



    const table = [
        { size: 1, name: "#", text: "#" },
        { size: 3, name: "date", text: "التاريخ", custom: d => _date(d.date).dmy() },
        { size: 3, name: "dead_count", text: "عدد النافق" },
        { size: 3, name: "total_report_egg_count", text: " البيض المدخل", custom: d => Helpers.Number.commaFormat(d?.total_report_egg_count?.toFixed(2)) },
        { size: 3, name: "standard_carton", text: " الكرتون المتداول", custom: d => Helpers.Number.commaFormat(d?.standard_carton?.toFixed(2)) },
        { size: 3, name: "week_age", text: "عمر الطير ", custom: d => Helpers.Number.commaFormat(d?.week_age?.toFixed(2)) },
        { size: 4, name: "classification", text: "التصنيف" },
        { size: 2, name: "production_percentage", text: "نسبة الانتاج", custom: d => Helpers.Number.commaFormat(d?.production_percentage?.toFixed(2)) },
        {
            size: 2, name: '', text: '', className: "no-tooltip", custom: d => {
                return <div className="d-flex">
                    {/* <button className="mx-1 no-button " onClick={() => updateRef.current.toggle(d)} ><Icons.Edit /> </button>
                    <button className="mx-1 no-button " onClick={() => deleteRef.current.toggle(d)} ><Icons.Delete  /> </button> */}
                    <button className="mx-1 no-button " onClick={() => viewRef.current.toggle(d)} ><i className="fa fa-eye" /></button>
                </div>
            }
        }
    ]

    const buttons = [
        {
            title: " اضافة تقرير بيض جديد",
            onclick: () => createRef.current.toggle()
        }
    ]


    return (
        <>
            <PageMeta title={'تقارير البيض اليوميه'} />
            <QuickView ref={createRef}><ReportsCrud.Eggs.Create setRefresh={setRefresh} setMainData={setData} /></QuickView>
            <QuickView ref={updateRef}><ReportsCrud.Eggs.Update setRefresh={setRefresh} setMainData={setData} /></QuickView>
            <QuickView ref={deleteRef}><ReportsCrud.Eggs.Delete setRefresh={setRefresh} setMainData={setData} /></QuickView>
            <QuickView ref={viewRef}><ReportsCrud.Eggs.View setMainData={setData} /></QuickView>
            <Signboard {...alert} />
            <TableHeader buttons={buttons} api={api} setData={setters.data} setLoading={setters.loading} paginate={paginate} />
            <Table data={data} table={table} updateRef={updateRef} deleteRef={deleteRef} />
        </>
    )
}
