import React, { useEffect } from 'react'
import { accio } from '../../helpers/axios/axios.get'
import useDebounce from '../../hooks/useDebounce'
import { IoIosClose, IoIosSearch } from 'react-icons/io'
import usePaginateStore, { getStoreApi } from '../../stores/paginate.store'

export default function Search(props) {

  const state  = usePaginateStore((state) => state.search)
  const setState = usePaginateStore((state) => state.setSearch)
  const setDebounce = usePaginateStore((state) => state.setDebounce)
  const firstRender = usePaginateStore((state) => state.firstRender)
  const setPagesCount = usePaginateStore((state) => state.setPagesCount)
  const setPerPage   = usePaginateStore((state) => state.setPerPage)
  const setTotal  = usePaginateStore((state) => state.setTotal)
  
  const {api , setData , setLoading } = props


  const debounced = useDebounce(state)



  const resetState = () => {
   if(setLoading) return setLoading(true)
    setState("")

  }




  useEffect(() => {

    // is makes the search no send requests in the first render
    // if any update happened in page , search  it will set to false and then it will send requests 
    if(firstRender) return 


    setDebounce(debounced)


    const abortController = new AbortController()

    const signal = abortController.signal

    const fetchData = async (search) => {

     if(setLoading) return setLoading(true)


      const query = api + getStoreApi()


      const {data , paginate} = await accio(query , {} , signal)


      setPerPage(paginate?.total_per_page)
      setPagesCount(paginate?.pages)
      setTotal(paginate?.total)
      
      if (setLoading) setLoading(false)
      
      if(!data) return console.log('no search data')
        
        setData(data)


      
    }

     fetchData(debounced)


    return () => abortController.abort()


  } , [debounced , api])




  return (
    <>

      <div className='relative bg-[#f2f2f2] rounded-[25px] w-[400px]  flex  items-center  '>               
              <div className="w-[30px] h-[30px]  mr-4 cursor-pointer !text-[28px]">
                      { !state && <IoIosSearch className=' opacity-40' /> }
                      { state && <IoIosClose className=' opacity-40 ' onClick={resetState}  />}
              </div>


              <input type="text" value={state} onChange={(e)=>setState(e.target.value)} placeholder='البحث في القائمه'
                    className=' w-full h-full rounded-[5px]  bg-transparent outline-none border-none  p-0 text-[16px] text-[#333] px-3 ' />

      </div>

    </>
  )
}
