import { useRef } from "react";
import { useParams } from "react-router-dom";
import { _date, PageMeta, QuickView, Signboard, TableHeader, useFetch } from "../../../../config/imports";
import ReportsCrud from "../reports_crud";
import { reports_api } from "../../../../config/apis";
import { Table } from "../../../../layout/table";

export default function LightDailyReportForProductionHome(props){
    const  createRef = useRef()

    const {id} = useParams();

    const api = reports_api.home.lights.read(id)

    const  { data  ,  alert , paginate , setters , setRefresh}  = useFetch(api);

    const table = [
        {name : "#"  , text : "" , size : 1  },
        {name : "date"  , text : "تاريخ الاضاءة" , size : 4 , custom : d => _date(d?.date).dmy() },
        {name : "start_date"  , text : "تاريخ البداية" , size :3  , custom : d => _date(d?.start_date).dmy() },
        {name : "start_time"  , text : "وقت البداية" , size :3 , custom : d => _date(d?.start_date).to12Format() },
        {name : "end_date"  , text : "تاريخ النهاية" , size :3  , custom : d => _date(d?.end_date).dmy() },
        {name : "end_time"  , text : "وقت النهاية" , size :3 , custom : d => _date(d?.end_date).to12Format() },
        {name : "light_duration"  , text : "مدة الاضاءة بالساعه" , size :3 },
        {name : "strain_light_duration"  , text : "مدة الاضاءة بالكتالوج" , size :3 },
    ]

    const buttons =  [
        {
            title: " اضافة تقرير اضاءة  جديد",
            onclick: () => createRef.current.toggle()
        }
    ]


    return(
        <div className="report-container">
            <PageMeta title={'تقارير الاضاءة اليوميه'} />
            <Signboard {...alert}/>
            <QuickView ref={createRef}><ReportsCrud.Lights.Create   setRefresh={setRefresh} /></QuickView>
            <TableHeader  buttons={buttons}  api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate} />
            <Table data={data} table={table}  />
        </div>
    )
}
