import React from "react";
import { cn } from "../../helpers/class_name.helpers";

export default function OptionsList({ options, selected, onChange, setPage , page , maxPageCount }) {
  
  const uniqueOptions = Array.from(
    new Map(options.map(opt => [opt.value, opt])).values()
  );

  return (
    <div className="flex flex-col gap-2">
      {uniqueOptions.map((option) => (
        <Option
          key={option.value}
          value={option.value}
          selected={selected}
          onChange={onChange}>
          {option.label}
        </Option>
      ))}

      {
        options.length === 0 && (
          <div className="flex items-start justify-center  text-gray-400 rounded-md p-1 py-2">
            لا يوجد نتائج
          </div>
        )
      }

      {
        options.length > 0 && maxPageCount > 1 && page < maxPageCount && (
          <button
            type="button"
            onClick={() => setPage(prev => prev + 1)}
            className="flex items-start justify-center hover:bg-gray-100 hover:text-gray-600 rounded-md p-1">
            حمل المزيد
          </button>
        )
      }
    </div>
  );
}

const Option = ({ value, children, selected, onChange }) => {
  const isSelected = selected.filter((s) => s === value).length > 0;
  return (
    <div
      onClick={() => onChange(value)}
      className={cn(
        "p-2 hover:bg-gray-100 cursor-pointer hover:text-gray-600 rounded-md text-[14px]",
        isSelected && "bg-gray-100 text-gray-600 "
      )}>
      {children}
    </div>
  );
};
