import React from 'react'
import { cn } from '../../../helpers/class_name.helpers'

export default function PreviewDetailsItem({title , value , valueClassName = "" , titleClassName = "" , className = ""}) {
  return (
    <div className={cn(
      'flex flex-col mb-4',
      className
    )}>
        <span className={cn('mb-2' , titleClassName)}>{title}</span>

        <div className={cn(
          'border-border border-[1px] text-[15px]  bg-slate-100 h-[40px] rounded-md flex items-center justify-start  truncate  px-2',
          valueClassName
        )}>
            {value}
        </div>
    </div>
)
}
