import { useState } from "react";
import { apis } from "../../../../config/apis";
import { createFormHandler, setInputsToDefault } from "../../../../helpers/form.helper";
import { EditForm, InputSelect, InputText, InputTextArea, PageMeta, Signboard } from "../../../../config/imports";
import InputDrugUnits from "../../../../components/Shared/InputDrugUnits";
import InputOperator from "../../../../shared/components/operator/InputOperator";

export default function Create(props){

    const {setMainData, toggle , operators , setRefresh} = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        name: '',
        measuringUnit: '',
        types: '',
        operator: '',
        classification: '',
        info: '',
    })

    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };


    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const create_props = {
            url: apis.origins.drug.create(),
            data ,
            setLoading,
            setError,
            setSuccess,
            setMainData : ()=>{},
            setRefresh,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };

        await createFormHandler(create_props);
    };
    return(
        <>
            <PageMeta title={' اضافة نوع دواء جديد  '} originTitle={' الادوية'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>اضافة نوع دواء جديد</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>
               <InputText name={'name'} className={'col-6'}  title={'الاسم'} value={data.value} />
              
               {/* <InputSelect name={'operator'} title={'المورد '} className={'col-6'}  value={data.operator}  options={ ArrayHelpers.makeOptionsForInputSelect(operators)} /> */}

             <InputOperator className={"col-6"}  />
               
               
               <InputDrugUnits className="col-6"  setData={setData} />

                <InputSelect className='col-6' name={'classification'} title={'التصنيف '} value={data.classification}  options={[
                    {_id : "تحصين" , name : "تحصين" },
                    {_id : "دواء" , name : "دواء" },
                    {_id : "فتامين" , name : "فتامين" },
                    {_id : "مطهرات" , name : "مطهرات" },
                ]} />
   

                <InputTextArea name={'info'} title={'التفاصيل'} value={data.info} />


            </EditForm>

        </>
    )
}
