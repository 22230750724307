import { useCallback, useEffect, useMemo, useState } from 'react'
import useAlert from '../../../hooks/useAlert'
import axiosPost from '../../../helpers/axios/axios.post'
import { apis } from '../../../config/apis'
import { InputSelect } from '../../../config/imports'

export default function useInvoicesApi(query , {data : mainData , setData : setMainData }) {
    const {Alert , setters , states , signboard} = useAlert()
    const [data , setData] = useState(null)
    const [error , setError] = useState(null)

    const fetchForm = useCallback( async () => {
            setters.loading()
            const { error  , data} = await axiosPost(apis.invoices.form() , query)
            setters.reset()
            if(error)
             {
                setError(error)
                return setters.error( error )
             }
            setters.reset()
            setData(data)
            setError(null)
      })  



    useEffect(() => {
           if(query) 
            fetchForm(query)
    } , [])



    const ordersOptions = useMemo(() => {
        return data?.all_orders?.map( o => ({ text : o.order_number , value : o._id  }) )
    })

    const onToggleChange = (e) => {
        // console.log(e.target.checked);
        const newValue = e.target.checked
        setMainData(prev => {
            return {...prev , enable_tax : newValue}
        })
        
    }

    const OrdersElement = (props) => {
      // const {defaultValue , handleInputChange} = props
      return  <div className='col-12 p-0 flex items-center '>
           <InputSelect 
                  handleInputChange={props?.handleInputChange} name="orders" 
                  title="الطلبات" options={ordersOptions}  defaultValue={props?.defaultValue ?? []}  
                  className="col-12" multiple={true} />
          {/* <div className="col-2">
            <span className='block  relative bottom-3'>تفعيل الضريبه</span>
            <ul className="tg-list mb-0 flex justify-end"  dir="ltr">
                  <li className="tg-list-item m-0 p-0">
                      <input className="tgl tgl-ios" id="cb2" checked={mainData.enable_tax} onChange={onToggleChange} type="checkbox"/>
                      <label className="tgl-btn" htmlFor="cb2"></label>
                  </li>
              </ul>
          </div> */}

      </div>
    } 



  
  return {
      formApis : apis,
      Alert, error , data,
      setters , 
      loading : states.loading,
      success : states.success,
      error : states.error,
      signboard,
      ordersOptions,
      OrdersElement
  }
}