import React, { useMemo } from 'react'
import { InputNumber, InputText, useFetch } from '../../../config/imports'
import { apis } from '../../../config/apis'
import useInvoiceItemsStore from '../stores/invoice-items.store'
import Helpers from '../../../helpers/helpers'

export default function InputTax(props) {

    const {data , setData , className  } = props

    const {data : taxData} = useFetch(apis.preferences.tax())

    const getTotalPrice = useInvoiceItemsStore(state => state.getTotalPrice)

    const totalPrice = !props.isProject ?  Helpers.validateNumber( getTotalPrice() )  :    +data.quantity * +data.unit_price


    const priceAfterTax = useMemo(() => {

      const percentage = taxData?.tax_percentage / 100

      const discount = 1 + percentage

      const _total_price =  totalPrice


      const price  =  (_total_price * discount).toFixed(2)

      return isNaN(price) ? 0 : price
      

    } , [data.quantity , data.unit_price])


  return (
    <div className={className}>
        <div className="row">
            <InputText 
              className={'col-4'} 
              name={'tax_percentage'} 
              title={'الضريبة'} 
              disabled={true}  
              defaultValue={data.enable_tax ?  (taxData?.tax_percentage + "%") : "0%" } 
          />
            <InputNumber 
              className={'col-8'} 
              name={'tax_percentage'} 
              title={'الاجمالي بالضريبة'} 
              disabled={true}  
              defaultValue={data.enable_tax ? (+priceAfterTax || 0).toFixed(2) : (( totalPrice  ).toFixed(2) ?? 0)  } 
          />
        </div>
    </div>


  )
}
