import React from 'react'
import { useCollectionCenterStore } from '../store/collection-center.store'
import CollectionCenterItem from './CollectionCenterItem'

export default function CollectionCenters() {

  const centers = useCollectionCenterStore(state => state.data)

  return (
    <div className='flex gap-2 flex-wrap'>
        {centers.map(c =><CollectionCenterItem key={c._id} data={c} />  )}
    </div>
  )
}
