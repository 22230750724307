import {InputNumber} from "../../../config/imports";
import Helpers from "../../../helpers/helpers";
import useInvoiceItemsStore from "../stores/invoice-items.store";

export default function AmountInput(props){
    const {data , setData , defaultValue} = props
    const onItemChange = useInvoiceItemsStore(state => state.onItemChange)

    const handleInputChange = (e) => {
        const { value} = e.target;
        setData( { ...data , quantity : +value , total_price : Helpers.validateNumber( data.unit_price *  (+value))})

        if(data.isChecked){
                let _homes = data.home
                const homeCount = _homes.length
                _homes.forEach(home=>{
                        home.quantity = +( +value / homeCount).toFixed(2)
                })

                setData({...data , home : _homes , total_quantity_from_homes : +value })
        }


        if(props.item){
            const {item} = props
            onItemChange('quantity' , +value , item.id)
        }

    };


    const componentType =  { 
        base :{                
                name : 'quantity' ,
                placeholder : "0",
                title : 'الكمية',
                handleInputChange,
                className : 'w-full',
        },
        create : ()=> <InputNumber {...componentType.base}  value={data.quantity} />,
        update : ()=> <InputNumber {...componentType.base}  defaultValue={data.quantity} />
    }


    return(
        <>
            {props.defaultValue ?  componentType.update() : componentType.create() }
        </>
    )
}
