import React from 'react'

export default function InputEnableTax({ data, setData }) {

  const onToggleChange = (e) => {
    // console.log(e.target.checked);
    const newValue = e.target.checked
    setData(prev => {
      return { ...prev, enable_tax: newValue }
    })

  }



  return (
    <div className="col-12">
      <span className='block  relative bottom-3 whitespace-nowrap'>تفعيل الضريبه</span>
      <ul className="tg-list mb-0 flex justify-end" dir="ltr">
        <li className="tg-list-item m-0 p-0">
          <input className="tgl tgl-ios" id="cb2" checked={data.enable_tax} onChange={onToggleChange} type="checkbox" />
          <label className="tgl-btn" htmlFor="cb2"></label>
        </li>
      </ul>
    </div>
  )
}
