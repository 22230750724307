import { useEffect, useState } from "react";
import {RouterLinks} from "../../components/LinkList";
import { apis } from "../../config/apis";
import {useFetch, useNavigator, useParams } from "../../config/imports";
import { DEPARTMENT_TYPE } from "../../config/local_storage.config";
import useHomeType from "./reports/hooks/useHomeType";
import useHomeBreadCrumbs from "./hooks/useHomeBreadCrumbs";


const departmentsEnum = {
         breeding_egg : [
                {name: "تقارير النافق",  path: "dead"  },
                {name: "تقارير الاعلاف",  path: "feed"  },
                {name: "تقارير الادوية",  path: "drugs"  },
                {name: "تقارير الصيانه",  path: "maintenance"  },
                {name: "تقارير اخرى",  path: "other"  },
                {name : "تقارير الاضاءة" , path : 'light'},
                {name : "تقارير الاوزان" , path : 'weight'},
         ],
         production_egg : [
                {name: "تقارير النافق",  path: "dead"  },
                {name: "تقارير البيض",  path: "eggs"  },
                {name: "تقارير الاعلاف",  path: "feed"  },
                {name: "تقارير الادوية",  path: "drugs"  },
                {name: "تقارير الصيانه",  path: "maintenance"  },
                {name: "تقارير اخرى",  path: "other"  },
                {name: "تقارير الكراتين",  path: "cartons"  },
                {name: "تقارير الاطباق",  path: "dishes"  },
                {name : "تقارير الاضاءة" , path : 'light'},
                {name : "تقارير الاوزان" , path : 'weight'},
         ],
         breeding_chicken : [
                {name: "تقارير النافق",  path: "dead"  },
                {name: "تقارير الاعلاف",  path: "feed"  },
                {name: "تقارير الادوية",  path: "drugs"  },
                {name: "تقارير الصيانه",  path: "maintenance"  },
                {name: "تقارير اخرى",  path: "other"  },
                {name : "تقارير الاضاءة" , path : 'light'},
                {name : "تقارير الاوزان" , path : 'weight'},
         ],
         production_chicken : [
                {name: "تقارير النافق",  path: "dead"  },
                {name: "تقارير البيض",  path: "eggs"  },
                {name: "تقارير الاعلاف",  path: "feed"  },
                {name: "تقارير الادوية",  path: "drugs"  },
                {name: "تقارير الصيانه",  path: "maintenance"  },
                {name: "تقارير اخرى",  path: "other"  },
                {name: "تقارير الكراتين",  path: "cartons"  },
                {name: "تقارير الاطباق",  path: "dishes"  },
                {name : "تقارير الاضاءة" , path : 'light'},
                {name : "تقارير الاوزان" , path : 'weight'},
         ],
}

export default function HomeReports(props){

    const {data} = useHomeType()


    useHomeBreadCrumbs()

    useNavigator( "reports" ,"dead")

    return data && <RouterLinks data={departmentsEnum[data?.type ?? []]}  />
}
