const Loading = () => {
  return (
    <div className="loading-container">
      <img
        src="/loading.gif"
        width={"100px"}
        style={{ margin: "auto", display: "block" }}
        alt="loading"
      />
    </div>
  );
};

export default Loading;



// import { cn } from "../helpers/class_name.helpers";

// const Loading = () => {
//   return (
//     <div className={cn(
//       'loading-container flex items-center justify-center rounded-lg  m-auto bg-white/90 shadow-md p-2 ',
//       'w-fit absolute left-1/2 -translate-x-1/2 z-[100000000000000]'
//     )}>
//       <img
//         src="/loading.gif"
//         style={{ margin: "auto", display: "block" }}
//         alt="loading"

//         className="min-w-[100px] min-h-[100px]"
//       />
//     </div>
//   );
// };

// export default Loading;
