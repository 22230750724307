import React from 'react'
import Skeleton from './Skeleton'

export default function SkeletonGroup() {
  return (
    <>
      <SkeletonGroupRow />
      <SkeletonGroupRow />
      <SkeletonGroupRow />
      <SkeletonGroupRow />
      <SkeletonGroupRow />   
    </>
  )
}




 function SkeletonGroupRow() {
  return (
    <div className="flex gap-2 justify-between my-3">
        <Skeleton  className={"h-9 flex-1 rounded-md"}   />
        <Skeleton  className={"h-9 flex-1 rounded-md"}   />
        <Skeleton  className={"h-9 flex-1 rounded-md"}   />
        <Skeleton  className={"h-9 flex-1 rounded-md"}   />
        <Skeleton  className={"h-9 flex-1 rounded-md"}   />
        <Skeleton  className={"h-9 flex-1 rounded-md"}   />
        <Skeleton  className={"h-9 flex-1 rounded-md"}   />
        <Skeleton  className={"h-9 flex-1 rounded-md"}   />
    </div>
  )
}
