import React, { useEffect, useState } from 'react';
import { cn } from '../../../helpers/class_name.helpers';
import { createPopup } from "airpop";
import UpdateCollectionCenter from "../forms/UpdateCollectionCenter";
import DeleteCollectionCenter from "../forms/DeleteCollectionCenter";
import ChangeCollectionCenterColor from "../forms/ChangeCollectionCenterColor";
import Icons from "../../../layout/Icons";

export default function CollectionCenterMenu({data}) {

  const [state , setState] = useState(false)

  const edit = createPopup(<UpdateCollectionCenter />)
  const del = createPopup(<DeleteCollectionCenter />)
  const color = createPopup(<ChangeCollectionCenterColor />)

  useEffect(() => {
    if(state){  
      setTimeout(() => { 
        setState(false)
      }, 2000)
    }
  } , [state])


  useEffect(() => {
    const handleOutsideClick = (event) => {
      const isPopup = event.target.closest("[data-keep-collection-menu-open]")? true : false;
      if(!isPopup) return setState(false);
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);


  return (
    <>
      
        <button 
        data-keep-collection-menu-open
        onClick={() => setState(!state)} className='absolute -top-1 right-3 w-fit h-fit text-white  '>
          <b className="text-white text-[20px]">. . .</b>
        </button>

        <div 
        data-keep-collection-menu-open
        className={cn(
          'absolute top-[20%]  w-[90%]  flex items-center justify-center transition-all duration-500 ease-in-out bg-white/90 ',
          "h-0 overflow-hidden rounded-md",
          state && "h-[70%]"
        )}>

          <div className="w-full h-full flex flex-col  gap-2">
            <button onClick={() => edit(data)} className="w-full    flex items-center gap-2 justify-between px-2  hover:bg-slate-200">
              <p className='mb-0'>تعديل</p>
              <Icons.Edit />
            </button>
            <button onClick={() => del(data)} className="w-full    flex items-center gap-2 justify-between px-2  hover:bg-slate-200">
              <p className='mb-0'>حذف</p>
              <Icons.Delete />
            </button>
            <button onClick={() => color(data)} className="w-full    flex items-center gap-2 justify-between px-2  hover:bg-slate-200">
              <p className='mb-0'>تغيير اللون</p>
              <Icons.Color />
            </button>


          </div>

            
        </div>

    </>
  )
}
