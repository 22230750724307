import React from 'react';
import CollectionCenterMenu from "./CollectionCenterMenu";
import { useCollectionCenterStore } from '../store/collection-center.store';
import { Link } from 'react-router-dom';

export default function CollectionCenterItem(props) {

  const { data  } = props

  const userId = useCollectionCenterStore(state => state.user.id)

  const _color = data.system_info?.filter(c => c.user_id === userId)[0]?.color

  const color = _color ??  "#41e481";


  return (
    <Link to={"/collection-center/"+ data._id }  style={{background : color}}
    className='flex relative px-2  text-white text-[18px]  items-center justify-center text-center flex-col w-[150px] h-[150px] border-border border-[1px] rounded-lg '>
        {data.name}
        <CollectionCenterMenu data={data} />
    </Link>
  )
}


