import React from "react";
import { BiTrash } from "react-icons/bi";
import { cn } from "../../helpers/class_name.helpers";

export default function SelectedOption({content , clearSelection}) {
  return (
    <div
      className="cursor-pointer w-fit relative group"
      onClick={(e) => {
        e.stopPropagation();
        clearSelection();
      }}>
      <div className="z-[5]">{content}</div>
      <div
        className={cn(
          "absolute w-full h-full flex items-center justify-center z-[10] top-0 left-0 bg-red-500/50 rounded-md opacity-0",
          "group-hover:!opacity-[1]",
          "transition-all duration-300 ease-in-out"
        )}>
        <BiTrash className="fill-white" />
      </div>
    </div>
  );
}
