import React, { useMemo, useState } from "react";
import useStockTransferForm from "../../stocks/hooks/useStockTransferForm";
import { createFormHandler } from "../../../helpers/form.helper";
import { apis } from "../../../config/apis";
import Signboard from "../../../components/Signboard";
import {
  EditForm,
  InputNumber,
  InputSelect,
  InputText,
} from "../../../config/imports";
import { useParams } from "react-router-dom";
import useHomeHierarchical from "../../../hooks/useHomeHierarchical";
import useUnits from "../../../hooks/useUnits";
import Helpers from "../../../helpers/helpers";
import useStock from "../../stocks/useStock";
import { useEffect } from "react";
import InputFeed from "../../../shared/components/origin-feed/InputFeed";

export default function CreateHomeFeedTransfer(props) {
  const { id } = useParams();

  const { toggle, setRefresh, type, header } = props;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  // console.log(type);
  const signboard = { loading, error, success };

  const { Inputs, data, setData, submitDataType, options } =
    useStockTransferForm(id, type, "home");

  const { options: homesOptions } = useHomeHierarchical();

  const { getUnitName, convertUnit } = useUnits();

  const { fetchQuantities, quantities } = useStock();

  useEffect(() => {
    fetchQuantities({ category: type, location_id: id });
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: type === "number" ? +value : value,
    }));
  };

  const locationOptions = useMemo(() => {
    return [
      { text: "المستودع", value: "stock" },
      { text: "بيت", value: "home" },
    ];
  }, []);

  const measuringUnits = useMemo(() => {
    return [{ text: "قطعه", value: "piece" }];
  }, []);

  const handleFormSubmit = async (e) => {
    if (loading) return;
    setLoading(true);

    e.preventDefault();

    const create_props = {
      url: apis.storages.create(),
      data: {
        ...submitDataType[type],
        location: "home",
        location_id: id,
      },
      setLoading,
      setError,
      setSuccess,
      setRefresh,
      setData,
      FORM_HEADER: `الاضافة`,
    };
    // console.log(create_props.data);
    await createFormHandler(create_props);
  };

  const defaultUnit =
    data.unit === "feed" || type === "feed"
      ? data.unit !== ""
        ? data.unit
        : "ton"
      : "piece";

  return (
    <>
      <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
        <h3> اضافة حركه اعلاف جديده </h3>
      </header>
      <Signboard {...signboard} />
      <EditForm
        visible={true}
        onSubmit={handleFormSubmit}
        toggle={toggle}
        onChange={handleInputChange}>
        <InputText
          disabled={true}
          className={"col-6"}
          name="location_id"
          title="من"
          defaultValue={"بيت"}
        />

        <div className="col-6 mb-[10px]  relative">
          <InputText
            disabled={true}
            className={"w-100"}
            name="location_id"
            title="الجهه"
            defaultValue={homesOptions.getText(id)}
          />
          {quantities && (
            <div className="fs-14  absolute bottom-[-10px] ">
              {" "}
              الكمية المتاحه للتحويل :
              <span className="text-secondary ">
                {" "}
                {Helpers.Number.commaFormat(
                  convertUnit(
                    quantities[type]?.quantity,
                    quantities[type]?.unit,
                    defaultUnit
                  )
                )}{" "}
              </span>
              <span> {getUnitName(defaultUnit)} </span>
            </div>
          )}

          {!quantities && (
            <div className="fs-14  absolute bottom-[-10px] text-amber-400">
              {" "}
              جاري تحميل الكمية المتاحه...{" "}
            </div>
          )}
        </div>

        <InputSelect
          name="_to"
          className="col-6"
          label="الى"
          options={locationOptions}
          defaultValue={data._to}
        />
        {data._to === "home" ? (
          <div className="col-6 p-0">
            <InputSelect
              handleInputChange={handleInputChange}
              options={homesOptions.all}
              name="destination_id"
              className="col-12"
              label="اختر البيت"
            />
          </div>
        ) : (
          <InputSelect
            options={options.stocks}
            name="destination_id"
            className="col-6"
            title="اختر المستودع"
          />
        )}

        {/* <Inputs.FeedType  className="col-4"  name="sub_category"  title="نوع العلف" defaultValue={data.sub_category} /> */}
        <div className="col-4 ">
          {/* <InputDataType handleInputChange={handleInputChange} className="col-12" name="sub_category" title="النوع " defaultValue={data.sub_category} /> */}

          <InputFeed
            handleInputChange={handleInputChange}
            className="col-12 px-0"
            name="sub_category"
            title="اختر النوع "
          />
        </div>

        {type === "feed" ? (
          <Inputs.Unit
            name="unit"
            title=" وحدة القياس"
            defaultValue={data.unit}
          />
        ) : (
          <InputSelect
            name="unit"
            title=" وحدة القياس"
            defaultValue={data.unit}
            options={measuringUnits}
          />
        )}

        <InputNumber
          name="consumption"
          title="الكميه"
          defaultValue={data.consumption}
        />
        {/* <InputDate className="col-3"  name="date"  title="التاريخ" defaultValue={_date(data.date).forInputDate()}  /> */}
      </EditForm>
    </>
  );
}
