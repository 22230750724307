import { createContext, useContext, useState } from 'react';
import { SelectContext } from './Select';
import { cn } from '../../helpers/class_name.helpers';
import { BiCheck } from 'react-icons/bi';



 const Option = ({ value, children , text = value }) => {
  const context = useContext(SelectContext);

  if (!context) 
    throw new Error("Option must be used within a Select component");
  
  const { onSelect, selectedValue } = context;

  return (
    <div
      onClick={() => onSelect(value, children)}
      className={cn(
        "cursor-pointer hover:bg-slate-100 p-2 px-4 my-1 rounded-md relative",
        selectedValue === value && "bg-slate-100"
      )}
    >
      {children}
      {selectedValue === value && (
        <BiCheck className="absolute right-1 top-1/2 transform -translate-y-1/2" />
      )}
    </div>
  );
};


export default Option