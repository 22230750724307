import React from 'react';
import { cn } from '../../helpers/class_name.helpers';

export default function Skeleton({ className}) {
  return (
    <div className={cn(
      ' bg-slate-100 animate-pulse  ',
      className
    )}   />
  );
}
