import React from 'react'
import { apis } from '../../../config/apis';
import useFetch from '../../../hooks/useFetch';
import { _date } from '../../../helpers/date.helpers';
import Helpers from '../../../helpers/helpers';
import PreviewDetailsItem from './PreviewDetailsItem';
import { ImSpinner2 } from 'react-icons/im';


const api = ( type  , id)=>{
  return apis.invoices.base()  +"/" + type + "/" + id
}

export default function PreviewStockInvoice(props) {


 
  const { data   , isLoading} = useFetch(api(props.item.en_category === "feed" ? "feedbuy" :  props.item.en_category , props.item._id))



  

  return (
    <div>
     
      <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
        <h3 className='flex items-center'>
          <span>          تفاصيل  فاتورة {data?.ar_type} { data?.ar_category } </span>
          <span className='mx-2 text-[16px] flex items-center'>
          {
              isLoading ?  <>( <ImSpinner2 className='animate-spin' /> )</> : <>( {data?.invNumber} )</> 
            }
          </span>
        </h3>
      </header>




      <div className="row items-center">
        <div className="col-3">
          <PreviewDetailsItem title="المسلسل" value={data?.invNumber} />
        </div>
        <div className="col-3">
          <PreviewDetailsItem title="رقم الفاتورة" value={data?.invoice_number} /> 
        </div>
        <div className="col-3">
          <PreviewDetailsItem title="المورد" value={data?.operator?.name} />
        </div>
        <div className="col-3">
          <PreviewDetailsItem title="تاريخ الفاتورة" value={data?.date && _date(data?.date).dmyt()} />
        </div>

        {data?.items?.map((item , index) => (
           <React.Fragment key={index}>
              <div className="col-3">
                <PreviewDetailsItem title="النوع" value={item?.sub_category?.name} />
              </div>
              <div className="col-3">
                <PreviewDetailsItem title="وحدة القياس" value={item?.ar_unit ?? item?.unit} /> 
              </div>
              <div className="col-2">
                <PreviewDetailsItem title="سعر الوحده" value={Helpers.Number.commaFormat(item?.unit_price)} />
              </div>
              <div className="col-2">
                <PreviewDetailsItem title="الكمية" value={Helpers.Number.commaFormat(item?.quantity)} />
              </div>
              <div className="col-2">
                <PreviewDetailsItem title="الاجمالي" value={Helpers.Number.commaFormat(item?.total_price)} />
              </div>
          </React.Fragment>
        ))}


        <div className="col-12">
          <PreviewDetailsItem title="اسم المستودع" value={data?.stock?.name} />
        </div>
        <div className="col-3">
          <PreviewDetailsItem title="الاجمالي قبل الضريبه" value={Helpers.Number.commaFormat(data?.total_price)} />
        </div>
        <div className="col-3">
          <PreviewDetailsItem title="نسبة الضريبه" value={data?.tax_percentage + " %"} />
        </div>
        <div className="col-3">
          <PreviewDetailsItem title="قيمة الضريبه" value={Helpers.Number.commaFormat(data?.tax_value?.toFixed(2))} />
        </div>
        <div className="col-3">
          <PreviewDetailsItem title="الاجمالي بعد الضريبه" value={Helpers.Number.commaFormat(data?.price_with_tax)} />
        </div>
        <div className="col-12">
          <PreviewDetailsItem title="الملاحظات" value={data?.more_info} valueClassName='min-h-[100px]' />
        </div>
      </div>


      <div className="flex justify-end">
        <button onClick={props.toggle} className="close-btn  mt-3">اغلاق</button>
      </div>
    </div>
  )
}
