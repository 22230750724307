import React from "react";
import { operatorAPI, originAPI } from "../origin.helpers";
import Signboard from "../../../components/Signboard";
import { TableHeader, useFetch, QuickView, useRef, PageMeta, Icons } from "../../../config/imports";
import OriginOthersCrud from "./others_crud";
import Gadwal from "../../../shared/components/gadwal/Gadwal";
import QuickViewWithOverFlow from "../../../components/QuickViewWithOverFlow";

const Others = () => {

    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();

    const {data, error, setData: setMainData, isLoading , setRefresh} = useFetch(originAPI.other);

    const { data: operators  , isLoading :  operatorLoading , error : operatorError} = useFetch(operatorAPI.url);

    const signboard =  {
        error : error || operatorError,
        loading : isLoading || operatorLoading
    }


    const table = [
      {  size : 1 , name : "#" , header : "#" , custom : (d , idx) => idx},
      {  size : 5 , name : "name" , header : "الاسم"  },
      {  size : 3 , name  : "ar_unit"  , header : "وحدة القياس" },
      {  size : 6 , name : "operator" , header : "المورد"  , custom : d => d.operator?.name ?? 'بدون'  },
      {  size : 5 , name : "info" , header : "التفاصيل"  , custom : d => d.info || "-" },
      {  size : 2 , name : "" , header : "" , custom : d => {
          return <div className="flex items-center gap-2">
                     <button  onClick={()=> updateRef.current.toggle(d)}><Icons.Edit /></button>
                     <button  onClick={()=> deleteRef.current.toggle(d)}><Icons.Delete /></button>
          </div>
      }}
  ]

  return ( <>

        <PageMeta title={"الاخرى"} />
        <QuickViewWithOverFlow ref={createRef}><OriginOthersCrud.Create  operators={operators}  setRefresh={setRefresh}/></QuickViewWithOverFlow>
        <QuickViewWithOverFlow ref={updateRef}><OriginOthersCrud.Update  operators={operators} setRefresh={setRefresh}/></QuickViewWithOverFlow>
        <QuickView ref={deleteRef}><OriginOthersCrud.Delete  operators={operators} setRefresh={setRefresh}/></QuickView>
        <Signboard {...signboard} />
        <TableHeader  buttons={ [ { title: " اضافة نوع  اخرى  جديد ", onclick: () => createRef.current.toggle() } ]}/>
        <div className="" style={{marginTop: '150px'}}></div>
        <Gadwal data={data} table={table} />
    </>
  );
};

export default Others;
