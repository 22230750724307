import {InputSelect, useFetch} from "../../../config/imports";
import {apis} from "../../../config/apis";
import useInvoiceItemsStore from "../stores/invoice-items.store";

export default function FeedTypeSelectInput(props){
    const {data , setData , defaultValue} = props


    const {data : types} = useFetch( apis.origins.feed.base())

    const onItemChange = useInvoiceItemsStore(state => state.onItemChange)

    const handleInputChange = (e) => {
        const { value} = e.target;
        setData( { ...data , sub_category : value})

        if(props.item ){
            const {item   } = props

            onItemChange('sub_category' , value , item.id)

        }

    };
    return(
        <>
            <InputSelect
                className={'w-full'}
                handleInputChange={handleInputChange}
                name={'sub_category'} 
                title={'النوع '}
                defaultValue={defaultValue}
                nameKey={'name'}
                options={types}
            />

        </>
    )
}
