import { BiTrash } from "react-icons/bi";
import React, { useMemo } from 'react';
import MeasuringUnitInput from './MeasuringUnitInput';
import UnitPriceInput from './UnitPriceInput';
import AmountInput from './AmountInput';
import useInvoiceItemsStore from '../stores/invoice-items.store';
import useFetch from "../../../hooks/useFetch";
import { apis, packaging_api } from "../../../config/apis";
import { InputSelect } from "../../../config/imports";

export default function InputDishesGroup({data , setData}) {

  const {data : dishes } = useFetch(apis.dishes.all())

  const dishesOptions = useMemo(() => {
    if (!dishes) return []; // renders nothing if data is not available
    return dishes.map( dish => ({
        _id : dish._id , name : dish.name
    }))
} , [dishes])


  const {items , addRow , deleteRow} = useInvoiceItemsStore(state =>({
    items : state.items,
    addRow : state.addItem,
    deleteRow : state.deleteItem
  }))


  const onItemChange = useInvoiceItemsStore(state => state.onItemChange)

  const measuringUnits = useMemo(() => {
    return [
        { text : "قطعه" , value : "piece" },
    ]
} , [])


  return (
  items.map((item)=>(
    <div className='w-full  relative' key={item.id}>
      <div className="row g-3">

       <div className="col-3 ">
          <InputSelect  className={'w-full'} 
           handleInputChange={(e) => onItemChange('sub_category' , e.target.value , item.id)}
           defaultValue={data.sub_category} name={'sub_category'} options={ dishesOptions } title={'نوع الطبق'} />
       </div>

       <div className="col-3 ">
         <MeasuringUnitInput data={data} setData={setData}  item={item}  options={measuringUnits} />
       </div>

       <div className="col-2 ">
         <UnitPriceInput data={data} setData={setData}  item={item}  />
       </div>

       <div className="col-2 ">
         <AmountInput data={data} setData={setData} item={item}   /> 
       </div>
       <div className="col-2 ">
        <span className='mb-2 block'>الاجمالي</span>
        <span className=' border-border border-[2px] flex items-center px-2 h-[40px] rounded-md bg-slate-100'>
            {item.unit_price * item.quantity}
        </span>
       </div>

      </div>
        <button 
            onClick={addRow}
            type="button" className='absolute top-10  -right-7 border-border border-[1px] rounded-md p-1 py-0  hover:bg-[#86d993] hover:text-white group'>
              <span className="text-[30px] group-hover:text-white leading-[20px] text-[#fcaa30]">+</span>
            </button>
       {
        items.length > 1 && (
          <>
            <button 
            onClick={()=>deleteRow(item.id)}
            type="button"  className='absolute top-9  -left-7 border-red-200 border-[1px] rounded-md p-1  hover:bg-red-300 hover:text-white group'>
              <BiTrash className="!text-[20px] group-hover:text-red-300" />
            </button>
          </>
        )
       }
    </div>
  ))
  )
}
