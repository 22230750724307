import { useMemo, useState } from "react";
import {
    createFormHandler,
    EditForm, InputText, PageMeta, setInputsToDefault,
    Signboard
} from "../../../config/imports";
import { projects_api } from "../../../config/apis";
import { InputSelect, useFetch } from "../../../config/imports";
import ArrayHelpers from "../../../helpers/array.helpers";
import { stock_api } from "../../../config/apis";
import { cn } from "../../../helpers/class_name.helpers";
import { showErrorToast } from "../../../stores/toast.store";
import useCollectionCenter from "../../../hooks/origins/useCollectionCenter";

export default function CreateHome(props){

    const {setMainData, toggle } = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [selected, setSelected] = useState([]);
    const {data : stocks , error: stocksError , isLoading: stocksIsLoading} = useFetch(stock_api.base);


    const {  options } = useCollectionCenter()


    const signboard = {
        error : stocksError || error,
        loading : stocksIsLoading || loading,
        success : success
    }

    const [data , setData] = useState({
        name : '',
        stock : '',
        collection_center : ''
    })
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if(selected.length === 0) return showErrorToast("من فضلك قم باختيار قسم واحد على الاقل  ");
        // console.log(data)
        const create_props = {
            url: projects_api.create,
            data : {
                ...data,
                departments : selected
            },
            setLoading,
            setError,
            setSuccess,
            setMainData ,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };


        // console.log({data : create_props.data})
        await createFormHandler(create_props);
    };


        const departments = useMemo(() => {
                return [
                    {
                        name : "  تربية بياض",
                        value : "breeding_egg",
                    },
                    {
                        name : " انتاج بياض",
                        value : "production_egg",
                    },
                    {
                        name : " تربية امهات",
                        value : "breeding_chicken",
                    },
                    {
                        name : " انتاج امهات",
                        value : "production_chicken",
                    },
                ]
        } , [])
        const setSelectedDepartment = (value) => {
            if (!value) return;
            setSelected(prevSelected => {
                const isSelected = prevSelected.includes(value);
                if (isSelected)   return prevSelected.filter(d => d !== value);
                else  return [...prevSelected, value];
            });
        };

        const isSelected = (value) => {
            if (!value) return;
            const isSelected = selected.includes(value) ? true : false;
            return isSelected;
        };

    return(<>
        <PageMeta title={'إضافة مشروع جديد'} originTitle={'المشاريع'}/>

        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
            <h3>   إضافة مشروع جديد  </h3>
        </header>

        <Signboard {...signboard}/>

        <EditForm
            visible={props.showFooter === undefined}
            onSubmit={handleFormSubmit}
            toggle={toggle}
            onChange={handleInputChange}>
            <InputText    className={'col-4'} value={data.name}     name={'name'}  placeholder={'p1'}  title={'اسم المشروع'} />
            <InputSelect  className={'col-4'} value={data.stock} name={'stock'} options={ ArrayHelpers.makeOptionsForInputSelect(stocks) } title={'المستودع'} />
            <InputSelect  className={'col-4'} value={data.collection_center} name={'collection_center'} options={ options } title={'نقطة التجميع'} />
            <>
                    <div className="col-12 my-3">
                         اختر الاقسام الخاصه بالمشروع
                    </div>
                {
                    departments.map(dp => {               
                    return <div className="col-3 " key={dp.value}>
                            <div className={cn(
                                "bg-[#b9b7b4] text-center py-3 text-white rounded-md font-[18px] cursor-pointer relative group",
                                isSelected(dp.value) && "bg-[#41e481]",
                                !isSelected(dp.value) && "bg-[#b9b7b4]",
                            )}>
                                {dp.name}
                                <div 
                                onClick={()=> setSelectedDepartment(dp.value) }
                                className={cn(
                                    "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ",
                                    "bg-gray-100 bg-opacity-50 backdrop-filter backdrop-blur-sm border border-gray-300 rounded-md text-white",
                                    "w-full h-full flex items-center justify-center text-[24px] cursor-pointer",
                                    "hidden group-hover:flex",
                                )}>
                                {
                                    isSelected(dp.value) ? "-" : "+"
                                }
                            </div>
                            </div>


                    </div>



                    })
                }
            </>

        </EditForm>

    </> )
}
