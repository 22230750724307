import React, { useEffect, useMemo, useState } from "react";
import useStockTransferForm from "../../stocks/hooks/useStockTransferForm";
import { createFormHandler } from "../../../helpers/form.helper";
import { apis } from "../../../config/apis";
import Signboard from "../../../components/Signboard";
import {
  EditForm,
  InputNumber,
  InputSelect,
  InputText,
} from "../../../config/imports";
import { useParams } from "react-router-dom";
import useHomeHierarchical from "../../../hooks/useHomeHierarchical";
import useStockDrugs from "../../stocks/hooks/useStockDrugs";
import InputDrugList from "../../../shared/components/drug-origin/InputDrugList";
import { accio } from "../../../helpers/axios/axios.get";
import { showErrorToast } from "../../../stores/toast.store";

const fetchDrug = async (drugId) => {
  const response = await accio(apis.origins.drug.drugDetails(drugId));
  if (response.error) return showErrorToast(response.error);
  return response.data;
};

export default function CreateHomeDrugTransfer(props) {
  const { id } = useParams();

  const { toggle, setRefresh, type } = props;

  const [loading, setLoading] = useState(false);

  const [success, setSuccess] = useState("");

  const [error, setError] = useState("");

  const signboard = { loading, error, success };

  const { data, setData, submitDataType, options } = useStockTransferForm(
    id,
    "drug",
    "home"
  );

  const { options: homesOptions } = useHomeHierarchical();

  useEffect(() => {
    if (data.sub_category) {
      fetchDrug(data.sub_category)
        .then((response) => {
          setData((prev) => ({
            ...prev,
            ar_unit: response.ar_unit,
            unit: response.measuringUnit,
          }));
        })
        .catch((error) => {
          showErrorToast(error);
          setData((prev) => ({
            ...prev,
            ar_unit: null,
            unit: null,
          }));
        });
    } else {
      setData((prev) => ({
        ...prev,
        ar_unit: null,
        unit: null,
      }));
    }
  }, [data.sub_category]);



  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: type === "number" ? +value : value,
    }));
  };

  const locationOptions = useMemo(() => {
    return [
      { text: "المستودع", value: "stock" },
      { text: "بيت", value: "home" },
    ];
  }, []);

  const handleFormSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();

    const create_props = {
      url: apis.storages.create(),
      data: {
        ...submitDataType[type],
        location: "home",
        location_id: id,
      },
      setLoading,
      setError,
      setSuccess,
      setRefresh,
      setData,
      FORM_HEADER: `الاضافة`,
    };
    // console.log(create_props.data);
    await createFormHandler(create_props);
  };

  return (
    <>
      <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
        <h3> حركة ادوية جديده </h3>
      </header>
      <Signboard {...signboard} />
      <EditForm
        visible={true}
        onSubmit={handleFormSubmit}
        toggle={toggle}
        onChange={handleInputChange}>
        <InputText
          disabled={true}
          className={"col-6"}
          name="location_id"
          title="من"
          defaultValue={"بيت"}
        />

        <div className="col-6 mb-[10px]  relative">
          <InputText
            disabled={true}
            className={"w-100"}
            name="location_id"
            title="الجهه"
            defaultValue={homesOptions.getText(id)}
          />
        </div>

        <InputSelect
          name="_to"
          className="col-6"
          label="الى"
          options={locationOptions}
          defaultValue={data._to}
        />
        {data._to === "home" ? (
          <div className="col-6 p-0">
            <InputSelect
              handleInputChange={handleInputChange}
              options={homesOptions.all}
              name="destination_id"
              className="col-12"
              label="اختر البيت"
            />
          </div>
        ) : (
          <InputSelect
            options={options.stocks}
            name="destination_id"
            className="col-6"
            title="اختر المستودع"
          />
        )}

        {/* <Inputs.FeedType  className="col-4"  name="sub_category"  title="نوع العلف" defaultValue={data.sub_category} /> */}
        <div className="col-6">
          {/* <InputDrugsType handleInputChange={handleInputChange} className="col-12" name="sub_category" title="النوع " defaultValue={data.sub_category} /> */}

          <InputDrugList
            handleInputChange={handleInputChange}
            className="col-12 px-0"
            name="sub_category"
            title="النوع "
            defaultValue={data.sub_category}
          />
        </div>

        <>
          <div className="col-2 bottom-1">
            <label htmlFor="" className="mb-2.5">
              وحدة القياس
            </label>
            <div className="border-border border-[2px] flex items-center px-2 h-[40px] rounded-md bg-gray-100">
              {data.ar_unit}
            </div>
          </div>
        </>

        <InputNumber
          name="consumption"
          allowDecimal={false}
          title="الكميه"
          defaultValue={data.consumption}
        />
      </EditForm>
    </>
  );
}
