import React, { useRef } from 'react'
import PageMeta from '../../../components/PageMeta'
import useStock from '../useStock'
import { _date } from '../../../helpers/date.helpers'
import { Table } from "../../../layout/table"

import QuickView from '../../../components/QuickView'
import MovementDetails from '../forms/MovementDetails'
import useStockStore from '../stores/stock.store'
export default function DetailsTab() {
  const { transfers } = useStockStore(state => state)

  const detailsRef = useRef()

  const { Alert, categories, CategoriesFilter } = useStock({
    location: "stock",
    category: "",
  })

  const table = [
    { name: "#", text: "", size: 1 },
    { name: "ar_type", text: "نوع الحركه", size: 2 },
    { name: "from", text: "من", size: 4 },
    { name: "to", text: "الي", size: 3 },
    { name: "category", text: "الصنف", size: 3, custom: d => categories[d?.category] },
    // { name : "sub_category" , text : "الفئة" , size : 2 },
    { name: "quantity", text: "الكمية", size: 3 },
    { name: "ar_unit", text: "الوحده", size: 2 },
    { name: "date", text: "التاريخ", size: 3, custom: d => <span style={{ fontSize: "13px" }}> {_date(d?.date).dmy()} </span> },
    {
      name: "movements", text: "تفاصيل", size: 2, custom: d => {
        return <button onClick={() => detailsRef.current?.toggle(d)}><i className="fa fa-eye " /></button>
      }
    },
  ]

  return (
    <>
      <div className='page base-card'>
        {<QuickView ref={detailsRef} className="overflown-popup"><MovementDetails categories={categories} /></QuickView>}
        <Alert />
        <PageMeta title="تفاصيل المستودع " />
        {/* <TableHeader buttons={[ { title : "إضافة حركه جديده" , onclick : () => createRef.current.toggle() } ]} /> */}
        <div className="row align-items-center">
          <div className="col-9">
            <h3> التحركات   </h3>
          </div>
          <div className="col-3">
            <CategoriesFilter />
          </div>
        </div>
        <Table data={transfers} table={table} />
      </div>
    </>
  )
}
