import React from 'react';
import useCollectionCenters from './hooks/useCollectionCenters';
import AddCollectionCenterButton from './components/AddCollectionCenterButton';
import CollectionCenters from './components/CollectionCenters';

export default function CollectionCenter() {

  useCollectionCenters()

  return (
    <div className='flex gap-2 flex-wrap page'>
      <AddCollectionCenterButton />
      <CollectionCenters />
    </div>
  )
}
