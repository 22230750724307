import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Signboard from '../Signboard';
import EditForm from '../FormControls/EditForm';
import { showErrorToast } from '../../stores/toast.store';
import { createFormHandler } from '../../helpers/form.helper';

function CreateForm(props) {

    const { state ,  setState  , children ,  header  ,
         setRefresh ,  api  , setData  , toggle , ...rest} = props
 
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const signboard = { loading , error , success, } 

    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setState((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if(loading) return


        if (rest.validation) {
            const {error} = rest.validation({
                ...state,
                ...rest.customState(state)
            })
            if(error) return showErrorToast(error)
        }

        setLoading(true);

        const create_props = {
            url: api ,
            data : {
                ...state,
                ...rest.customState(state)
            } ,
            setLoading,
            setError,
            setSuccess,
            setRefresh,
            setState,
            setMainData : ()=>{},
            FORM_HEADER: `الاضافة`,
            onSuccess : (data) => {
                if(rest.onSuccess) rest.onSuccess(data)
            }
        };
        // console.log(create_props.data)

        await createFormHandler(create_props);




    };

  return (
    <>
        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
            <h3> {header}</h3>
        </header>
        <Signboard {...signboard}/>
        <EditForm visible={true} onSubmit={handleFormSubmit} toggle={toggle} onChange={handleInputChange}>
             {children}
        </EditForm>
    </>
  )
}

CreateForm.propTypes = {
    children : PropTypes.any,
    setRefresh : PropTypes.func,
    api : PropTypes.string,
    customState: PropTypes.any,
    header : PropTypes.string,
    initState: PropTypes.any,
    state : PropTypes.any,
    setState : PropTypes.func,
}

export default CreateForm
