import { BiTrash } from "react-icons/bi";
import React from "react";
import UnitPriceInput from "./UnitPriceInput";
import AmountInput from "./AmountInput";
import useInvoiceItemsStore from "../stores/invoice-items.store";
import useFetch from "../../../hooks/useFetch";
import { apis } from "../../../config/apis";
import InputDrugList from "../../../shared/components/drug-origin/InputDrugList";
import { accio } from "../../../helpers/axios/axios.get";
import { showErrorToast } from "../../../stores/toast.store";

const fetchDrug = async (drugId) => {
  const response = await accio(apis.origins.drug.drugDetails(drugId));
  if (response.error) return showErrorToast(response.error);
  return response.data;
};

export default function InputDrugsGroup({ data, setData }) {

  const { items, addRow, deleteRow } = useInvoiceItemsStore((state) => ({
    items: state.items,
    addRow: state.addItem,
    deleteRow: state.deleteItem,
  }));

  const onItemChange = useInvoiceItemsStore((state) => state.onItemChange);

  const onDrugChange = async (e, item) => {
    onItemChange("sub_category", e.target.value, item.id);

    if (!e.target.value) {
      onItemChange("unit", null, item.id);
      onItemChange("ar_unit", null, item.id);
      return;
    }

    const _details = await fetchDrug(e.target.value);

    if (!_details) {
      onItemChange("unit", null, item.id);
      onItemChange("ar_unit", null, item.id);
      showErrorToast("لم يتم العثور على الدواء");
      return;
    }

    const unit = _details.measuringUnit;

    const arUnit = _details.ar_unit;

    onItemChange("unit", unit, item.id);

    onItemChange("ar_unit", arUnit, item.id);

    //  onItemChange('unit' , value , item.id)
  };

  return items.map((item) => (
    <div className="w-full  relative" key={item.id}>
      <div className="row g-3">
        <div className="col-4">
          <InputDrugList
            handleInputChange={(e) => onDrugChange(e, item)}
            className="col-12 relative top-[10px]"
            name={"sub_category"}
          />
        </div>

        <div className="col-2 ">
          {/* <MeasuringUnitInput data={data} setData={setData}  item={item}  options={measuringUnits}  /> */}
          <label htmlFor="" className="mb-2.5">
            وحدة القياس
          </label>
          <div className="border-border border-[2px] flex items-center px-2 h-[40px] rounded-md bg-gray-100">
            {item.ar_unit}
          </div>
        </div>

        <div className="col-2 ">
          <UnitPriceInput data={data} setData={setData} item={item} />
        </div>

        <div className="col-2 ">
          <AmountInput data={data} setData={setData} item={item} />
        </div>
        <div className="col-2 ">
          <span className="mb-2 block">الاجمالي</span>
          <span className=" border-border border-[2px] flex items-center px-2 h-[40px] rounded-md bg-slate-100">
            {(item.unit_price * item.quantity).toFixed(2)}
          </span>
        </div>
      </div>
      <button
        onClick={addRow}
        type="button"
        className="absolute top-10  -right-7 border-border border-[1px] rounded-md p-1 py-0  hover:bg-[#86d993] hover:text-white group">
        <span className="text-[30px] group-hover:text-white leading-[20px] text-[#fcaa30]">
          +
        </span>
      </button>
      {items.length > 1 && (
        <>
          <button
            onClick={() => deleteRow(item.id)}
            type="button"
            className="absolute top-9  -left-7 border-red-200 border-[1px] rounded-md p-1  hover:bg-red-300 hover:text-white group">
            <BiTrash className="!text-[20px] group-hover:text-red-300 fill-red-500" />
          </button>
        </>
      )}
    </div>
  ));
}
