import { useState } from "react";
import { updateFormHandler } from "../../helpers/form.helper";
import { operatorAPI } from "./operator.routes";
import PageMeta from "../../components/PageMeta";
import Signboard from "../../components/Signboard";
import { EditForm, FormInput, InputSelect, InputText, InputTextArea, InputTextAsNumber } from "../../config/imports";


const FORM_HEADER = "تعديل عملاء او موردين أو مناديب";

const MAIN_TITLE = "تعديل عملاء او موردين أو مناديب";
const UpdateOperator = (props)=>{


    const {operator , item , toggle , setMainData} = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [isDataChanged, setIsDataChanged] = useState(false);

    const [componentData, setComponentData] = useState({
        name:item.name,
        type:item.type,
        place:item.place,
        city:item.city,
        phone:item.phone,
        mobile:item.mobile,
        email:item.email,
        website: item.website,
        tax_number:item.tax_number,
        postal_code:item.postal_code,
        more_info:item.more_info,
        mail_box:item.mail_box,
        residency_number:item.residency_number,
        commercial_register:item.commercial_register
    });

    const operatorTypes = [
        { _id: "مورد", name: "مورد" },
        { _id: "عميل", name: "عميل" },
        { _id: "مندوب", name: "مندوب" },
    ];
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const update_props = {
            url: operatorAPI.base + '/' + item._id ,
            data: componentData,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            FORM_HEADER,
            setIsDataChanged,
            item,
        };

       await updateFormHandler(update_props);
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setComponentData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return   (

        <div className="temp-form">

            <PageMeta title={"تعديل بيانات " + item.name} originTitle={FORM_HEADER} />

            <header className="text-center border-bottm-line w-fit mx-auto px-5 pb-3">
                <h3>تعديل بيانات {item.name}</h3>
            </header>

            <Signboard error={error} success={success} loading={loading} />


            <EditForm
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}
                visible={true}
            >
                <InputText title={"الاسم"} className={"col-12 col-sm-12 col-md-6 col-lg-6"} name={"name"} defaultValue={componentData.name} placeholder={"محمد"} /> 
                <InputSelect title={"النوع"} className={"col-12 col-sm-12 col-md-3 col-lg-3"} name={"type"} defaultValue={componentData.type} options={operatorTypes}/> 
                <InputText title={"المكان"} className={"col-12 col-sm-12 col-md-3 col-lg-3"} name={"place"} defaultValue={componentData.place} placeholder={"السعودية"}/> 
                <InputText title={"المدينة"} className={"col-12 col-sm-12 col-md-4 col-lg-4"} name={"city"} defaultValue={componentData.city} placeholder={"الرياض"}/>
                <InputTextAsNumber defaultValue={componentData.phone} className={"col-12 col-sm-12 col-md-4 col-lg-4"} name={"phone"} label={"الهائف"} invalid={"من فضلك ادخل ارقام فقط"} placeholder={"012345"}/>
                <InputTextAsNumber defaultValue={componentData.mobile} className={"col-12 col-sm-12 col-md-4 col-lg-4"} name={"mobile"} label={"رقم الموبيل"} invalid={"من فضلك ادخل ارقام فقط "} placeholder={"012345"}/>
                <FormInput className={"col-12 col-sm-12 col-md-6 col-lg-6"} name={"email"} label={"البريد الالكتروني"} type={"email"} defaultValue={componentData.email} placeholder={"a@a.com"}/>
                <InputText title={"الرقم الضريبى"} className={"col-12 col-sm-12 col-md-3 col-lg-3"} name={"tax_number"} defaultValue={componentData.tax_number} placeholder={"9xx-xx-xxxx"} />
                <InputText title={"الرمز البريدى"} className={"col-12 col-sm-12 col-md-3 col-lg-3"} name={"postal_code"} defaultValue={componentData.postal_code} placeholder={"012345"} />
                <InputText title={"صندوق البريد"} className={"col-12 col-sm-12 col-md-6 col-lg-6"} name={"mail_box"} defaultValue={componentData.mail_box} placeholder={"St.No 134 Riyadh 145-134"} />
                <InputText title={"الموقع الالكتروني"} className={"col-12 col-sm-12 col-md-6 col-lg-6"} name={"website"} defaultValue={componentData.website} placeholder={"www.example.com"} />
                <InputTextAsNumber label={"السجل التجاري الخاص بالمؤسسة"} className={"col-12 col-sm-12 col-md-6 col-lg-6"} name={"commercial_register"} defaultValue={componentData.commercial_register} placeholder={"1010123456"} />
                <InputTextAsNumber label={"رقم الاقامة او الرقم الوطني"} className={"col-12 col-sm-12 col-md-6 col-lg-6"} name={"residency_number"} defaultValue={componentData.residency_number} placeholder={"2345678901"} />
                <InputTextArea title={"لمحة موجزة"} className={"col-12"} name={"more_info"} defaultValue={componentData.more_info} placeholder={"اكتب هنا لمحة موجزة عن العميل"} />
            </EditForm>

        </div>

    )






}


export default UpdateOperator;
