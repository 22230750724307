
const homeDetails = {
        origin: "homes",
        navigateTo: "details",
        title: "البيوت",
        links: [
            {to: "details",name: "بيانات البيت"},
            {to: "reports",name: " التقارير"},
            {to: "orders",name: "الطلبات"},
            {to: "transfers",name: "التحركات"},
            {to: "eggs-warehouse",name: "مستودع البيض"},
            {to: "targeted",name: "المُستهدف"},
        ],
    };
export default function HomeDetailsIndex(props){

    // console.log("HomeDetailsIndex");
    
    return <></>
}

export {
    homeDetails
}
