import { useEffect, useState } from "react";
import { apis } from "../../../config/apis";
import {InputSelect, useFetch} from "../../../config/imports";
import useInvoiceItemsStore from "../stores/invoice-items.store";

export default function MeasuringUnitInput(props){
    const {data , setData , defaultValue} = props
    const [options , setOptions] = useState([])
    const { data : units } = useFetch(apis.units.mass.all())
    const onItemChange = useInvoiceItemsStore(state => state.onItemChange)

    
    useEffect(() => {
        setOptions(
            units?.map((unit) => {
                return {value : unit.unit , text : unit.ar_name}
            }))
    } , [units])
    const handleInputChange = (e) => {
        const { value} = e.target;
        setData( { ...data , unit : value})


        if(props.item){
            const {item  } = props
            onItemChange('unit' , value , item.id)
        }
    };

    return(
        <>
            <InputSelect name={'unit'} title={'وحدة القياس'} 
             handleInputChange={handleInputChange} 
             defaultValue={defaultValue}
              className={'w-full'} options={props.options ?? options ?? []} />
        </>
    )
}

