import {InputNumber} from "../../../config/imports";
import {useEffect} from "react";
import Helpers from "../../../helpers/helpers";
import useInvoiceItemsStore from "../stores/invoice-items.store";
export default function TotalPriceInput(props){
    const {data , setData , className  , defaultValue} = props


    const getTotalPrice = useInvoiceItemsStore(state => state.getTotalPrice)



    const _totalPrice = (!props.isProject) ? getTotalPrice() : (+data.quantity * +data.unit_price)



    const totalPrice = Helpers.validateNumber( _totalPrice) 
                       
    

    const handleInputChange = (e) => {
        setData( { ...data , total_price : totalPrice})
    };

    return( <InputNumber 
        className={className ?? 'col-6'} name={'total_price'} 
        title={'إجمالي المبلغ'} handleInputChange={handleInputChange} 
        defaultValue={ totalPrice   ?? defaultValue } /> )
}
