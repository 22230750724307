import { IoIosColorPalette } from "react-icons/io"; 
import React, { useMemo, useState } from 'react'
import { apis } from '../../../config/apis'
import useFetch from '../../../hooks/useFetch'
import { useParams } from 'react-router-dom'
import PageMeta from '../../../components/PageMeta'
import Helpers from "../../../helpers/helpers";
import { cn } from "../../../helpers/class_name.helpers";
import { _date } from "../../../helpers/date.helpers";


export default function Targeted() {

  const id = useParams().id

  const [rand , setRand] = useState(0)

  const changeColors = () => {
    setRand(Math.random())
  }

 
  
 
  const api = apis.homes.target(id)

  const {data} = useFetch(api)

  
   const {colors , colorSet} =   useMemo(() => {
      if (!data) return [];

      const colorSet = {};
  
      const colors = new Array(data.levels.length).fill(0).map((_, idx) => {
        let color, light;
       
        color = generateRandomColor();
        light = adjustColorLightness(color, 0.2);
        
  
        return colorSet[data.levels[idx]] = {
          bg: light,
          border: color,
          color: color,
        };
      });
  
      return {colors , colorSet};

    }, [data , rand]);
  


  return (
    <div className='flex-1 flex flex-col  w-full '>
        <PageMeta title={"المستهدف"}  />

        <div className="flex justify-end mb-2 items-stretch ">
          <button onClick={changeColors} className='flex items-center gap-1 border-border border-[1px] px-3 py-2 rounded-md bg-slate-200'>
            <span>تغيير الالوان</span>
            <IoIosColorPalette className="text-[27px]" />
          </button>
        </div>
        <div className='flex w-full flex-nowrap items-stretch  overflow-auto flex-1 border-l-[1px]'>
          {data?.result?.map((item , index) => {
            return (
              <div className={cn(
                "min-w-[180px]  border-border border-[1px] border-l-0  bg-white p-0 min-h-full"
              )} key={index}>
                  <div className="min-h-[100px] flex  flex-col h-full">
                      <header className='w-full border-b-[1px] border-b-border flex items-center justify-center p-2 bg-slate-100 '>
                          <h3 className='font-[400] text-[18px] mb-0'>{item.ar_month}</h3>
                      </header>
                      <div className="flex-1">
                        <div className="p-2  flex flex-col  gap-2 h-fit ">
                          {
                            item.levels.map((level , idx) => {
                              const _colors = colorSet[level.level_id]
                              return (
                                <div key={idx} className='flex flex-col gap-2 flex-1'>
                                  <div style={{ borderColor: _colors.border, backgroundColor: _colors.bg }}
                                    className="flex-1 rounded-md p-2  border-[1px] ">
                                      <div className="flex flex-col  mt-2 gap-1  text-[14px]">
                                          <span className="flex-1 font-[600]" style={{ color  : _colors.color }} >المستهدف</span>
                                          <span className="flex-1 " style={{ color  : _colors.color }} >{Helpers.Number.commaFormat(level.target_count)}</span>
                                      </div>
                                      <div className="flex flex-col  mt-2 gap-1  text-[14px]">
                                      <span className="flex-1 font-[600]" style={{ color  : _colors.color }} >عدد الدجاج</span>
                                          <span className="flex-1" style={{ color  : _colors.color }} >{Helpers.Number.commaFormat(level.checken_number)}</span>
                                      </div>
                                      <div className="flex flex-col  mt-2 gap-1  text-[14px]">
                                      <span className="flex-1 font-[600]" style={{ color  : _colors.color }} > تاريخ البدء</span>
                                          <span className="flex-1" style={{ color  : _colors.color }} >{_date(level.start_date).local()}</span>
                                      </div>
                                      <div className="flex flex-col  mt-2 gap-1  text-[14px]">
                                      <span className="flex-1 font-[600]" style={{ color  : _colors.color }} > تاريخ الانتهاء</span>
                                          <span className="flex-1" style={{ color  : _colors.color }} >{_date(level.end_date).local()}</span>
                                      </div>
                                  </div>
                                </div>
                              )
                            }
                            )
                          }
                        </div>
                      </div>
                      <div className="h-[50px] border-t-[1px] border-t-border flex items-center justify-center bg-green-100">
                            {Helpers.Number.commaFormat(item.total_count)}
                      </div>
                  </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}



function adjustColorLightness(hexColor, factor = 1) {
  factor = Math.max(0, Math.min(1, factor));

  hexColor = hexColor.replace('#', '');

  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  const newR = Math.round(r * factor + 255 * (1 - factor));
  const newG = Math.round(g * factor + 255 * (1 - factor));
  const newB = Math.round(b * factor + 255 * (1 - factor));

  return `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;
}

function generateRandomDarkColor(factor = 0.5) {
  const randomDarkValue = () => Math.floor(Math.random() * (256 * (1 - factor)));

  const r = randomDarkValue();
  const g = randomDarkValue();
  const b = randomDarkValue();

  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}


function generateRandomColor() {
  const randomValue = () => Math.floor(Math.random() * 256);

  const r = randomValue();
  const g = randomValue();
  const b = randomValue();

  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}
