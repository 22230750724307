import PageMeta from "../../../components/PageMeta";
import { TableHeader } from "../../../components/TableView";
import { Icons, QuickView, useFetch } from "../../../config/imports";
import { packaging_api } from "../../../config/apis";
import { useRef } from "react";
import { Link } from "react-router-dom";
import "../../../styles/billing.styles.scss";
import Create from "./Create";
import EditUnit from "./EditUnit";
import DeleteUnit from "./DeleteUnit";
import { Table } from "../../../layout/table";
import Helpers from "../../../helpers/helpers";
import QuickViewWithOverFlow from "../../../components/QuickViewWithOverFlow";

export default function EggStorageUnitsIndex() {
    const deleteRef = useRef(),
        createRef = useRef(),
        updateRef = useRef()

    const api = packaging_api.all


    const { data: units, error, isLoading, setData, Alert, paginate, setters, setRefresh } = useFetch(api)





    const table = [
        { name: "#", size: "1", text: "#" },
        { name: 'name', size: "3", text: 'الاسم' },
        { name: 'dishes_count', size: "3", text: 'عدد الاطباق' },
        { name: 'egg_count', size: "3", text: 'عدد البيض في كل طبق' },
        { name: 'total_egg_count', size: "3", text: 'عدد البيض في كل كرتونه' },
        { name: 'packaging_type', size: "3", text: 'نوع الكرتونة' },
        { name: 'dishes_type', size: "3", text: 'نوع الطبق' },
        {
            name: 'settings', size: "3", text: '', custom: d => {
                return <>
                    <button className="no-button mx-2" onClick={() => deleteRef.current.toggle(d)} > <Icons.Delete />  </button>
                    <button className="no-button mx-2" onClick={() => updateRef.current.toggle(d)} > <Icons.Edit />  </button>
                </>
            }
        },
        // { name : 'details' , size : "2" , text : '' , custom : d => {
        //     return <Link className="!flex items-center"  to={`/warehouses/origin/cartoons/details/${d._id}`} > تفاصيل <Icons.Arrow className="mx-2" />  </Link>
        // }
        // }
    ]

    const buttons = [{ title: 'اضافة  كرتونه جديده', onclick: () => createRef.current.toggle() }]

    return (
        <div className='egg-storage-unit base-card bg-white p-2 r-16 mt-2'>
            <PageMeta title={' الكراتين  '} />
            <Alert />
            <QuickViewWithOverFlow className="overflown-popup" ref={createRef}><Create setRefresh={setRefresh} setMainData={setData} /></QuickViewWithOverFlow>
            <QuickViewWithOverFlow className="overflown-popup" ref={updateRef}><EditUnit setRefresh={setRefresh} setMainData={setData} /></QuickViewWithOverFlow>
            <QuickView ref={deleteRef}><DeleteUnit setMainData={setData} setRefresh={setRefresh} /></QuickView>
            <div style={{ marginTop: "150px" }}></div>
            <TableHeader buttons={buttons} />
            <Table data={units} table={table} updateRef={updateRef} deleteRef={deleteRef} />
        </div>
    )
}
