import React, { Component } from "react";
import "../styles/modal.styles.scss";

class QuickView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: false,
      item: {},
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const isPopup = event.target.closest(".form-modal")? true : false;
    const isRendered =  document.querySelector(".form-modal[data-rendered='true']") ? true : false;
    if(!isPopup  && isRendered ) return this.setState({ state: false });
  };


  

  select = (item) => {
    this.setState({ item });
  };

  toggle = (item) => {
    if (item) this.select(item);
    this.setState({ state: !this.state.state });
  };

  render() {


    return (
      this.state.state && (
        <div className={"modal-container " + (this.props.className ?? "")} ref={(node) => (this.modalContainerRef = node)}>
          <div className={"form-modal p-3 row " + (this.props.modalClassName ?? "")} data-rendered={this.state.state ?? false} >
            <div className={"modal-container-content "  + (this.props.sized ? "sized-height" : "")  + (this.props.contentClassName ?? "") }>
              <div className="modal-component">
                {React.cloneElement(this.props.children, {
                  toggle: this.toggle,
                  item: this.state.item && this.state.item,
                })}
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default QuickView;
