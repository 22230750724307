import {InputNumber} from "../../../config/imports";
import Helpers from "../../../helpers/helpers";
import useInvoiceItemsStore from "../stores/invoice-items.store";

export default function UnitPriceInput(props){
    const {data , setData , defaultValue} = props
    const onItemChange = useInvoiceItemsStore(state => state.onItemChange)

    const handleInputChange = (e) => {

        const { value} = e.target;

        setData( { ...data , unit_price : +value , total_price : Helpers.validateNumber( data.unit_price *  (+value))})
        
        if(props.item){
            const {item  } = props
            onItemChange('unit_price' , +value , item.id)
        }
    };

    return(
        <>
            <InputNumber className={'w-full'}
             name={'unit_price'} placeholder={'0'}  title={'سعر الوحدة'} 
             handleInputChange={handleInputChange} value={data.unit_price} 
             defaultValue={defaultValue} 
             />
        </>
    )
}
